"use client";

import { R } from "app/(platform)/meeting-room/_types";
import { AppStore } from "app/_contexts/ReduxProvider";
import { validateParams } from "app/_helpers/validateParams";
import ILog from "app/_lib/global/Log";
import { LocalParticipant, Participant, RemoteParticipant, Room } from "livekit-client";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { selectActiveAgendaItem } from "./agendaItemSlice";
import { selectBreakoutByParticipantIdentity } from "./breakoutSlice";
import ListeningRequest from "./listeningRequest";
import LK from "./livekit/livekit";
import { selectActiveRound, selectNextInactiveRound } from "./roundSlice";
import Turn from "./turn";
import { selectActiveTurn, selectIncompleteTurnsByRoundId } from "./turnSlice";
export default class Round extends LK {
   Round: Partial<R.Round.Round>;
   constructor(p: { room: Room; Round: Partial<R.Round.Round>; store: AppStore }) {
      super(p);
      this.Round = p.Round;
   }

   startRound(breakoutId: string) {
      validateParams({ breakoutId });

      const state = this.store.getState();
      const agendaItem = selectActiveAgendaItem(state, breakoutId);
      const activeRound = selectActiveRound(state, breakoutId);
      const startableRound = selectNextInactiveRound(state, breakoutId);

      const empathyCafe = state.meeting.empathyCafe;
      ILog.v("Round.startRound", { breakoutId, agendaItem, activeRound, startableRound, empathyCafe });
      if (activeRound) {
         this.endRound(breakoutId);
      }
      //
      if (startableRound) {
         this.sendDataPacket({
            payload: {
               type: "round/startRound",
               payload: {
                  id: startableRound.id,
                  fallback: { ...startableRound, roundActive: true }
               }
            }
         });
      } else if (empathyCafe) {
         if (!agendaItem || !breakoutId || !agendaItem?.empathyCafeConfig) {
            ILog.e("Error_Round_startRound", { breakoutId, agendaItem });
            throw new Error("round.startRound No agendaItem found");
         }
         const round = {
            id: v4(),
            agendaItemId: agendaItem.id,
            breakoutId: breakoutId,
            roundComplete: false,
            roundActive: true,
            roundPaused: false,
            empathyMode: "reflective",
            strictTime: agendaItem?.empathyCafeConfig?.strictTime!,
            speakerTime: agendaItem?.empathyCafeConfig?.speakerTime!,
            intermissionTime: agendaItem?.empathyCafeConfig?.intermissionTime!,
            created: DateTime.now().toMillis()
         } satisfies R.Round.Round;
         this.sendDataPacket({
            payload: {
               type: "round/addRound",
               payload: round
            }
         });
      } else {
         throw new Error("round.startRound No startable round found");
      }
      return this;
   }

   listeningRequestAudio(participant: Participant[], data: Partial<R.ListeningRequest.ListeningRequest>) {
      validateParams({ participant, data });
      const state = this.store.getState();
      // const autoMute = state.meeting.autoMute;
      const breakout = selectBreakoutByParticipantIdentity(state, data?.listenerIdentity || undefined);
      const activeTurn = selectActiveTurn(state, breakout?.id);

      if (activeTurn?.speakerIdentity === data.speakerIdentity) {
         let targetParticipant = Array.from(participant).find((p) => p.identity === data.listenerIdentity) as RemoteParticipant | LocalParticipant;

         if (!targetParticipant && this.localParticipant.identity === data.listenerIdentity) {
            targetParticipant = this.localParticipant as LocalParticipant;
            this.audioMute();
         } else if (targetParticipant) {
            this.remoteAudioMute(targetParticipant as RemoteParticipant);
         }
      }
      return this;
   }

   endRound(breakoutId: string) {
      validateParams({ breakoutId });
      const state = this.store.getState();
      const activeRound = selectActiveRound(state, breakoutId);
      if (!activeRound) {
         ILog.v("Round.endRound no round found", { breakoutId, activeRound });
         return this;
      }

      const incompleteTurns = selectIncompleteTurnsByRoundId(state, activeRound.id);

      if (incompleteTurns && incompleteTurns.length > 0) {
         incompleteTurns.forEach((turn) => {
            //

            this.sendDataPacket({
               payload: {
                  type: "turn/voidTurn",
                  payload: {
                     id: turn?.id,
                     fallback: {
                        ...turn,
                        void: true
                     }
                  }
               }
            });
         });
      }

      this.sendDataPacket({
         payload: {
            type: "round/endRound",
            payload: {
               id: activeRound.id,
               fallback: {
                  ...activeRound,
                  roundComplete: true,
                  roundActive: false,
                  roundPaused: false
               }
            }
         }
      });
      return this;
   }
   receiveEvent(params: R.Room.DataReceivedParams) {
      if (params.isSenderFacilitator || params.senderHost) {
         this.dispatch(params.action);
      } else {
         throw new Error(`Round.receiveEvent: Unauthorized  ${JSON.stringify(params)}`);
      }
   }
   exit(exitIdentity: string) {
      const state = this.store.getState();
      const localHost = state.meeting.localHost;
      // if the exiting participant is breakout.facilitatorIdentity, then pauseRound and its turns
      const breakout = selectBreakoutByParticipantIdentity(state, exitIdentity);

      if (breakout?.facilitatorIdentity === exitIdentity && localHost) {
         this.pauseEvent(breakout?.id);
      }
      new Turn({ room: this.room, Turn: {}, store: this.store }).exit(exitIdentity);
      new ListeningRequest({ room: this.room, ListeningRequest: {}, store: this.store }).exit(exitIdentity);
      return this;
   }
   pauseEvent(breakoutId: string | undefined) {
      // Pause the Round and the activeTurn.
      const activeRound = selectActiveRound(this.store.getState(), breakoutId);
      const activeTurn = selectActiveTurn(this.store.getState(), breakoutId);
      if (activeRound) {
         this.sendDataPacket({
            payload: {
               type: "round/pauseRound",
               payload: {
                  id: activeRound.id,
                  fallback: {
                     ...activeRound,
                     roundPaused: true
                  }
               }
            }
         });
      }
      if (activeTurn) {
         this.sendDataPacket({
            payload: {
               type: "turn/pauseTurn",
               payload: {
                  id: activeTurn.id,
                  fallback: {
                     ...activeTurn,
                     turnPaused: true
                  }
               }
            }
         });
      }
      return this;
   }
   resumeEvent(breakoutId: string) {
      // Resume the Round. The facilitator will resume the activeTurn manually.
      const activeRound = selectActiveRound(this.store.getState(), breakoutId);
      if (activeRound) {
         this.sendDataPacket({
            payload: {
               type: "round/resumeRound",
               payload: {
                  id: activeRound.id,
                  fallback: {
                     ...activeRound,
                     roundPaused: false
                  }
               }
            }
         });
      }

      return this;
   }
}
