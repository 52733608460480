"use client";
import { PayloadAction } from "@reduxjs/toolkit";

import Metadata from "app/(platform)/meeting-room/_services/livekit/metadata";
import { R } from "app/(platform)/meeting-room/_types";
import { AppStore } from "app/_contexts/ReduxProvider";
import { validateParams } from "app/_helpers/validateParams";
import ILog from "app/_lib/global/Log";
import { LocalParticipant, Participant, RemoteParticipant, RemoteTrackPublication, Room } from "livekit-client";
import { toast } from "react-toastify";
import { selectAllAgendaItems } from "../agendaItemSlice";
import { selectAllBreakouts } from "../breakoutSlice";
import { selectAllChatMessages } from "../chatMessageSlice";
import { selectAllListeningRequest } from "../listeningRequestSlice";
import { selectAllRounds } from "../roundSlice";
import { selectAllTurns } from "../turnSlice";
export default class LK {
   room: Room;
   localParticipant: LocalParticipant;
   store: AppStore;
   constructor(p: { room: Room; store: AppStore }) {
      this.room = p.room;
      this.localParticipant = p.room.localParticipant;
      this.store = p.store;
      return this;
   }
   async inviteParticipantsToWorkspace() {}
   onParticipantNameUpdated({ identity, name }: { identity: string; name: string }) {
      const chatMessages = selectAllChatMessages(this.store.getState());
      const byIdentity = chatMessages.filter((m) => m.identity === identity);
      const newChatMessages = byIdentity.map((m) => {
         return { id: m.id, changes: { name } };
      });
      ILog.v("onParticipantNameUpdated", { identity, name }, { byIdentity, newChatMessages, chatMessages });

      if (newChatMessages.length > 0) {
         this.sendDataPacket({
            payload: {
               type: "chatMessage/updateManyChatMessages",
               payload: newChatMessages
            }
         });
      }
   }

   setName(name: string) {
      this.localParticipant?.setName(name);
   }

   audioMute() {
      const p = this.localParticipant;

      p.setMicrophoneEnabled(!p?.isMicrophoneEnabled);
      this.dispatch({
         type: "meeting/setAudioEnabled",
         payload: !p?.isMicrophoneEnabled
      });

      return this;
   }
   remoteAudioMute = (p: RemoteParticipant) => {
      validateParams({ p });

      const enabled = p?.isMicrophoneEnabled;
      return this.sendDataPacket({
         payload: {
            type: "meeting/remoteAudioMute",
            payload: {
               targetParticipant: { identity: p?.identity },
               enabled: !enabled
            }
         }
      }).audioEvent(p, !enabled);
   };
   audioEvent(targetParticipant: RemoteParticipant | LocalParticipant, enabled: boolean) {
      validateParams({ targetParticipant });

      if (targetParticipant?.identity === this.localParticipant.identity) {
         this.dispatch({
            type: "meeting/toggleAudioAllowed",
            payload: enabled
         });

         if (!this.localParticipant.isMicrophoneEnabled) {
            toast("You can unmute yourself");
         } else if (this.localParticipant.isMicrophoneEnabled) {
            toast("You are muted");
            this.localParticipant.setMicrophoneEnabled(false);
            this.dispatch({
               type: "meeting/setAudioEnabled",
               payload: false
            });
         }
      } else {
         const targetP = this.room?.remoteParticipants?.get(targetParticipant?.identity);

         const audioTracks = targetP?.audioTrackPublications;
         if (audioTracks) {
            Array.from(audioTracks).forEach((value: [string: string, track: RemoteTrackPublication]) => {
               value[1].setEnabled(enabled);
            });
         }
      }
      return this;
   }

   videoMute() {
      const localParticipant = this.localParticipant;

      const state = this.store.getState();
      const isEnabled = state.meeting.videoEnabled;

      localParticipant
         ?.setCameraEnabled(!isEnabled)
         .then((t) => {
            this.dispatch({
               type: "meeting/setVideoEnabled",
               payload: !isEnabled
            });
         })
         .catch((err) => {
            if (!isEnabled) {
               toast.error("Can't start the camera. Please give your browser permission to use your camera.");
            }
         });

      return this;
   }
   remoteCameraMute(p: RemoteParticipant) {
      validateParams({ p });
      const enabled = p?.isCameraEnabled;

      return this.sendDataPacket({
         payload: {
            type: "TOGGLE_REMOTE_CAMERA",
            payload: {
               targetParticipant: p?.identity,
               enabled: !enabled
            }
         }
      });
      //.cameraEvent(p?.identity, !enabled);
   }

   cameraEvent(targetIdentity: string, enabled: boolean) {
      validateParams({ targetParticipant: targetIdentity });
      const targetParticipant = this.room?.remoteParticipants?.get(targetIdentity);

      if (targetIdentity === this.localParticipant.identity) {
         if (this.localParticipant.isCameraEnabled) {
            toast("Your camera was muted by the host.");
            this.localParticipant.setCameraEnabled(false);
            this.dispatch({
               type: "meeting/setVideoEnabled",
               payload: false
            });
         } else {
            ILog.v("cameraEvent", "Camera enabled by host");
            toast("Host is asking you to turn on your camera.", { toastId: "cameraEvent" });
         }
      } else if (targetParticipant) {
         const videoTracks = targetParticipant.videoTrackPublications;
         if (videoTracks) {
            videoTracks.forEach((track) => {
               track.setEnabled(enabled);
            });
         }
      }
      return this;
   }

   calculateRoles(participants: string[]) {
      validateParams({ participants });
      if (!this.room.metadata) {
         return {
            host: undefined,
            coHosts: [],
            facilitators: [],
            attendees: participants
         };
      }

      const metadata = Metadata.parseRoom({ md: this.room.metadata });
      const host = participants.find((participant) => {
         const { isHost } = Metadata.parseRoles(metadata, participant);
         return isHost;
      });
      const coHosts = participants.filter((participant) => {
         const { isCoHost } = Metadata.parseRoles(metadata, participant);
         return isCoHost;
      });
      const facilitators = participants.filter((participant) => {
         const { isFacilitator } = Metadata.parseRoles(metadata, participant);
         return isFacilitator;
      });
      const attendees = participants.filter((participant) => {
         const { isHost, isCoHost, isFacilitator } = Metadata.parseRoles(metadata, participant);
         const isAttendee = !isHost && !isCoHost && !isFacilitator;

         return isAttendee;
      });
      return {
         host: host,
         coHosts: coHosts,
         facilitators: facilitators,
         attendees: attendees
      };
   }

   sendMeetingState(targetParticipants: RemoteParticipant[]) {
      validateParams({ targetParticipants });
      const state = this.store.getState();

      const { meetingTitle, meetingDescription, meetingPassword, meetingAdjourned, empathyCafe } = state.meeting;
      ILog.v(
         "sendMeetingState",

         { agendaItems: selectAllAgendaItems(state) },
         { rounds: selectAllRounds(state) },
         { turns: selectAllTurns(state) },
         { listeningRequests: selectAllListeningRequest(state) },
         { breakouts: selectAllBreakouts(state) },
         { chatMessages: selectAllChatMessages(state) }
      );
      ILog.v("sendMeetingState", { mainBreakout: JSON.stringify(selectAllBreakouts(state).find((b) => b.mainBreakout)) });
      ILog.v("sendMeetingState", { targetParticipants });
      ILog.v("sendMeetingState", { meetingTitle, meetingDescription, meetingPassword, meetingAdjourned, empathyCafe });
      this.sendDataPacket({
         payload: {
            type: "meeting/updateMeeting",
            payload: {
               meetingTitle,
               meetingDescription,
               meetingPassword,
               meetingAdjourned,
               empathyCafe
            }
         },
         targetParticipants,
         dispatchLocally: false
      });
      this.sendDataPacket({
         payload: {
            type: "agendaItem/upsertAgendaItems",
            // TODO ZW: Check the stability of payload: undefined
            payload: selectAllAgendaItems(state)
         },
         targetParticipants,
         dispatchLocally: false
      });
      this.sendDataPacket({
         payload: {
            type: "round/upsertRounds",
            payload: selectAllRounds(state)
         },
         targetParticipants,
         dispatchLocally: false
      });
      this.sendDataPacket({
         payload: {
            type: "turn/upsertTurns",
            payload: selectAllTurns(state)
         },
         targetParticipants,
         dispatchLocally: false
      });
      this.sendDataPacket({
         payload: {
            type: "listeningRequest/upsertListeningRequests",
            payload: selectAllListeningRequest(state)
         },
         targetParticipants,
         dispatchLocally: false
      });
      this.sendDataPacket({
         payload: {
            type: "breakout/upsertBreakouts",
            payload: selectAllBreakouts(state)
         },
         targetParticipants,
         dispatchLocally: false
      });
      this.sendDataPacket({
         payload: {
            type: "chatMessage/upsertChatMessages",
            payload: selectAllChatMessages(state)
         },
         targetParticipants,
         dispatchLocally: false
      });
      return this;
   }

   normalizeParticipantProps(participants: Participant[], breakoutId: string): R.Breakout.FormBreakoutParticipantProps[] {
      validateParams({ participants, breakoutId });
      return participants.map((participant: Participant) => {
         return {
            identity: participant.identity,
            name: participant.name || "",
            breakoutId: breakoutId
         };
      });
   }

   sendDataPacket({ payload, dispatchLocally = true, targetParticipants }: { payload: PayloadAction<any>; dispatchLocally?: boolean; targetParticipants?: RemoteParticipant[] }) {
      validateParams({ payload });

      const strData = JSON.stringify(payload || {});
      const encoder = new TextEncoder();
      const encodedPayload = encoder.encode(strData);
      if (dispatchLocally) {
         this.dispatch(payload);
      }
      if (!this.localParticipant.permissions?.canPublishData) {
         ILog.w("sendDataPacket", "Cannot publish data packet", { permissions: this.localParticipant.permissions });
      }
      // publishData takes in a Uint8Array, so we need to convert it
      this.localParticipant
         .publishData(encodedPayload, {
            reliable: true,

            destinationIdentities: targetParticipants && targetParticipants.length > 0 ? targetParticipants.map((p) => p.identity) : undefined
         })
         .then((res) => {
            ILog.v("SENT_sendDataPacket", "Data packet sent", { res: JSON.stringify(res) });
         })
         .catch((err) => {
            ILog.e("ERROR_sendDataPacket", "Error sending data packet", { err });
         });

      return this;
   }
   simpleEvent(params: R.Room.DataReceivedParams) {
      // isFacilitator check here, dispatch an event to the host client for re-dispatch
      if (params.senderHost /* || params.isSenderFacilitator */) {
         this.dispatch(params.action);
      } else {
         throw new Error("simpleEvent: senderHost is false or undefined");
      }
   }
   dangerous_simpleEvent(params: R.Room.DataReceivedParams) {
      this.dispatch(params.action);
   }
   // dispatch = useDispatch<AppDispatch>();
   dispatch(payload: PayloadAction<any>) {
      this.store.dispatch(payload);
   }
}
