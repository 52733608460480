import { LocalParticipant, Participant, RemoteParticipant, Room } from "livekit-client";
import { createContext } from "react";

import { AppStore } from "app/_contexts/ReduxProvider";
import SVC from "../_services/livekit/service";

export type MeetingContextType = {
   empathyCafe: boolean;
   error: string | undefined;
   isLocalRecorder: boolean | undefined;
   livekitToken: string | undefined;
   localParticipant?: LocalParticipant;
   localHost: boolean;
   localCoHost: boolean;
   room: Room | undefined;
   roomParticipants: Participant[];
   remoteParticipants: RemoteParticipant[];
   recorders: RemoteParticipant[];
   LK: SVC;
};
export const MeetingContext = createContext<MeetingContextType>({
   localHost: false,
   localCoHost: false,
   LK: new SVC({ room: new Room(), store: null as unknown as AppStore }),
   roomParticipants: [],
   remoteParticipants: [],
   empathyCafe: false,

   isLocalRecorder: undefined,
   livekitToken: undefined,
   room: undefined,

   localParticipant: undefined,
   error: undefined,
   recorders: []
});
