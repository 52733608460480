"use client";
import { R } from "app/(platform)/meeting-room/_types";
import { AppStore } from "app/_contexts/ReduxProvider";
import { validateParams } from "app/_helpers/validateParams";
import { Room } from "livekit-client";
import AgendaItem from "../agendaItem";
import Breakout from "../breakout";
import ListeningRequest from "../listeningRequest";
import Round from "../round";
import Turn from "../turn";
import LKEvent from "./event";
import LK from "./livekit";

export default class SVC extends LK {
   constructor(p: { room: Room; store: AppStore }) {
      validateParams(p);
      super(p);
   }
   Breakout(p: { breakout?: R.Breakout.BreakoutRoom }) {
      const { breakout } = p;
      return new Breakout({ room: this.room, Breakout: breakout || {}, store: this.store });
   }
   Turn(p: { turn: Partial<R.Turn.Turn> }) {
      const { turn } = p;
      return new Turn({ room: this.room, Turn: turn, store: this.store });
   }
   ListeningRequest(p: { listeningRequest: R.ListeningRequest.ListeningRequest }) {
      const { listeningRequest } = p;
      return new ListeningRequest({ room: this.room, ListeningRequest: listeningRequest, store: this.store });
   }
   AgendaItem(p: { agendaItem: Partial<R.AgendaItem.AgendaItem> }) {
      const { agendaItem } = p;
      return new AgendaItem({ room: this.room, AgendaItem: agendaItem, store: this.store });
   }
   Round(p: { round: Partial<R.Round.Round> }) {
      const { round } = p;
      return new Round({ room: this.room, Round: round, store: this.store });
   }
   Event() {
      return new LKEvent({ room: this.room, store: this.store });
   }
}
