// room?.participants: Map(0) is undefined when room?.state === "disconnected"

export function validateParams<T>(required: Object = {} /* dependent: Array<Object> = [] */): T {
   const keys = Object.keys(required);
   const values = Object.values(required);
   keys.forEach((key, i) => {
      if (values[i] === undefined) {
         // throw new Error(`Undefined parameter: '${key}' is required.`);
         throw new Error(`Undefined parameter: '${key}' is required.`);
      }
   });

   /*    
   dependent.forEach((obj) => {
      const keys = Object.keys(obj);
      const values = Object.values(obj);
      if (values[0] !== undefined) {
         keys.forEach((key, i) => {
            if (values[i] === undefined) {
               throw new Error(`Undefined parameter '${key}' is required when '${keys[0]}' is defined.`);
            }
         });
      }
   }); */

   return required as T;
}
