"use client";

import { TrackReference } from "@livekit/components-core";
import { R } from "app/(platform)/meeting-room/_types";
import { AppStore } from "app/_contexts/ReduxProvider";
import { validateParams } from "app/_helpers/validateParams";
import ILog from "app/_lib/global/Log";
import { LocalParticipant, Participant, ParticipantTrackPermission, RemoteParticipant, RemoteTrackPublication, Room } from "livekit-client";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { ParticipantMetadata } from "../_types/metadata";
import AgendaItem from "./agendaItem";
import { selectAgendaItemById, selectAgendaItemsByClonedFrom } from "./agendaItemSlice";
import {
   selectAllBreakouts,
   selectBreakoutById,
   selectBreakoutByParticipantIdentity,
   selectDuplicateBreakoutsByParticipantIdentity,
   selectDuplicateWithinBreakoutByParticipantIdentity,
   selectMainBreakout
} from "./breakoutSlice";
import LK from "./livekit/livekit";
import Round from "./round";
import { selectIncompleteRoundsByAgendaItemId } from "./roundSlice";

export default class Breakout extends LK {
   Breakout: Partial<R.Breakout.BreakoutRoom>;
   constructor(p: { room: Room; Breakout: Partial<R.Breakout.BreakoutRoom>; store: AppStore }) {
      validateParams(p);
      super({ room: p.room, store: p.store });
      this.Breakout = p.Breakout;
   }
   setScreenSyncPath({ breakoutId, screenSyncPath }: { breakoutId: string; screenSyncPath: string }) {
      this.sendDataPacket({
         payload: {
            type: "breakout/setScreenSyncPath",
            payload: {
               id: breakoutId,
               screenSyncPath
            }
         }
      });
   }

   allPause() {
      const state = this.store.getState();
      const mainBreakout = selectMainBreakout(state);
      const allBreakouts = selectAllBreakouts(state);

      this.sendDataPacket({
         payload: {
            type: "breakout/setActive",
            payload: {
               id: mainBreakout?.id,
               is: true,
               fallback: { ...mainBreakout, active: true }
            }
         }
      });
      const pausedBreakouts = allBreakouts.filter((b) => {
         return !b.mainBreakout;
      });

      pausedBreakouts.forEach((b) => {
         this.sendDataPacket({
            payload: {
               type: "breakout/setActive",
               payload: {
                  id: b.id,
                  is: false,
                  fallback: { ...b, active: false }
               }
            }
         });
         new Round({ room: this.room, Round: {}, store: this.store }).pauseEvent(b.id);
      });
   }
   allResume() {
      const state = this.store.getState();
      const mainBreakout = selectMainBreakout(state);
      const allBreakouts = selectAllBreakouts(state);

      this.sendDataPacket({
         payload: {
            type: "breakout/setActive",
            payload: {
               id: mainBreakout?.id,
               is: true,
               fallback: { ...mainBreakout, active: true }
            }
         }
      });
      const pausedBreakouts = allBreakouts.filter((b) => {
         return !b.mainBreakout;
      });

      pausedBreakouts.forEach((b) => {
         this.sendDataPacket({
            payload: {
               type: "breakout/setActive",
               payload: {
                  id: b.id,
                  is: true,
                  fallback: { ...b, active: true }
               }
            }
         });
         new Round({ room: this.room, Round: {}, store: this.store }).resumeEvent(b.id);
      });
   }
   close(breakoutId: string) {
      const state = this.store.getState();
      const mainBreakout = selectMainBreakout(state);
      const targetParticipants = selectBreakoutById(state, this.Breakout.id || breakoutId)?.participants;

      // Pause activeRound and activeAgendaItem, if any
      new Round({ room: this.room, Round: {}, store: this.store }).pauseEvent(this.Breakout.id);
      new AgendaItem({ room: this.room, AgendaItem: {}, store: this.store }).close(this.Breakout.id);

      // transfer all participants to main breakout
      if (mainBreakout && targetParticipants) {
         targetParticipants.forEach((p) => {
            this.transferParticipant(p, mainBreakout.id, breakoutId);
         });
      }

      // Close the breakout
      this.sendDataPacket({
         payload: {
            type: "breakout/setClosed",
            payload: {
               id: this.Breakout.id,
               is: true,
               fallback: { ...this.Breakout, closed: true }
            }
         }
      });
   }
   create(props: { participants: Participant[] | undefined }) {
      //   const {participants} =  validateParams(props) as {participants: Participant[]};
      const mainBreakout = selectMainBreakout(this.store.getState());

      const id = v4();
      const participants = props?.participants?.map((p) => p.identity);
      const payload = {
         type: "breakout/addBreakout",
         payload: {
            id: id,
            active: true,
            participants,
            recorders: [],
            facilitatorIdentity: this.localParticipant.identity,
            mainBreakout: mainBreakout ? false : true,
            roomOpen: true,
            roomLocked: false,
            agendaStarted: false,
            agendaPaused: false,
            fallback: {
               id: id,
               active: true,
               participants,
               recorders: [],
               facilitatorIdentity: this.localParticipant.identity,
               mainBreakout: mainBreakout ? false : true,
               roomOpen: true,
               roomLocked: false,
               agendaStarted: false,
               agendaPaused: false
            }
         }
      };
      this.sendDataPacket({ payload });
      return payload.payload;
   }
   exit(exitIdentity: string, exitBreakoutId: string) {
      const state = this.store.getState();

      const breakoutRoom = selectBreakoutById(state, exitBreakoutId);

      this.sendDataPacket({
         payload: {
            type: "breakout/removeParticipant",
            payload: {
               id: exitBreakoutId,
               identity: exitIdentity,
               fallback: breakoutRoom
            }
         }
      });
      /*  if (breakoutRoom?.id) {
      } */
      new Round({ room: this.room, Round: {}, store: this.store }).exit(exitIdentity);
   }
   transferParticipant(targetIdentity: string, targetBreakout: string, exitBreakout: string) {
      // const { id, identity, exitBreakoutId } = validateParams({ id: targetBreakout, identity: targetIdentity, exitBreakoutId: exitBreakout })
      ILog.v("Breakout", "transferParticipant", { targetIdentity, targetBreakout, exitBreakout });
      this.exit(targetIdentity, exitBreakout);
      this.sendDataPacket({
         payload: {
            type: "breakout/addParticipants",
            payload: {
               id: targetBreakout,
               participants: [targetIdentity]
            }
         }
      });
   }
   facilitatorFallback(participantIdentities: string[]): string | undefined {
      const { host, coHosts, attendees } = this.calculateRoles(participantIdentities);
      if (host) {
         return host;
      } else if (coHosts?.length > 0) {
         return coHosts[0];
      } /* else if (attendees?.length > 0) {
         return attendees[0];
      } */

      return undefined;
   }
   checkDuplicateBreakout(identity: string) {
      const state = this.store.getState();
      const breakouts = selectDuplicateBreakoutsByParticipantIdentity(state, identity || "");
      const breakoutsWithin = selectDuplicateWithinBreakoutByParticipantIdentity(state, identity || "");

      if (breakouts.length > 0) {
         breakouts.forEach((breakout) => {
            if (breakout) {
               this.sendDataPacket({
                  payload: {
                     type: "breakout/removeParticipant",
                     payload: {
                        id: breakout?.id,
                        identity
                     }
                  }
               });
            }
         });
      }
      if (breakoutsWithin.length > 0) {
         breakoutsWithin.forEach((breakout) => {
            if (breakout) {
               this.sendDataPacket({
                  payload: {
                     type: "breakout/removeParticipant",
                     payload: {
                        id: breakout?.id,
                        identity
                     }
                  }
               });
            }
         });
      }
   }
   defaultBreakout({ connectedParticipant, activeEgress, breakoutId }: { connectedParticipant: Participant; activeEgress?: boolean; breakoutId?: string }) {
      validateParams({ connectedParticipant });
      const state = this.store.getState();

      const mainBreakout = selectMainBreakout(state);
      if (activeEgress) {
         if (breakoutId) {
            const recorderBreakout = selectBreakoutById(state, breakoutId);
            ILog.v("defaultBreakout_RECORDER", { mainBreakout, connectedParticipant, activeEgress, recorderBreakout });
         } else {
            ILog.e("ERROR", "defaultBreakout_RECORDER", { mainBreakout, connectedParticipant, activeEgress, breakoutId });
         }
      } else if (mainBreakout && !!connectedParticipant.identity) {
         ILog.v("defaultBreakout_MAIN_ROOM", { mainBreakoutId: mainBreakout?.id, name: connectedParticipant.name, activeEgress, breakoutId });
         this.sendDataPacket({
            payload: {
               type: "breakout/addParticipants",
               payload: {
                  id: mainBreakout.id,
                  participants: [connectedParticipant.identity]
               }
            }
         });
         if (breakoutId !== undefined && breakoutId !== mainBreakout?.id) {
            ILog.v("removing", "defaultBreakout", { mainBreakoutId: mainBreakout?.id, name: connectedParticipant.name, activeEgress, breakoutId });
            this.sendDataPacket({
               payload: {
                  type: "breakout/removeParticipant",
                  payload: {
                     id: mainBreakout.id,
                     identity: connectedParticipant.identity
                  }
               }
            });
         } else {
            ILog.v("not removing", "defaultBreakout", { mainBreakoutId: mainBreakout?.id, name: connectedParticipant.name, activeEgress, breakoutId });
         }
      } else {
         ILog.e("ERROR", "defaultBreakout", { mainBreakoutId: mainBreakout?.id, name: connectedParticipant.name, activeEgress, breakoutId });
      }
   }
   breakoutToast = (message: string, variant: "info" | "success" | "warning" | "error") => {
      this.sendDataPacket({
         payload: {
            type: "DISPATCH_TOAST",
            payload: {
               id: v4(),
               message: message,
               variant: variant,
               updated: DateTime.now().toMillis()
            }
         }
      });
   };
   joinRequest(params: { targetParticipant: Participant | LocalParticipant | undefined }) {
      const { targetParticipant } = validateParams({ targetParticipant: params?.targetParticipant }) as { targetParticipant: Participant | LocalParticipant };
      const currentBreakout = selectBreakoutByParticipantIdentity(this.store.getState(), targetParticipant.identity || "");
      this.sendDataPacket({
         payload: {
            type: "BREAKOUT_JOIN_REQUEST",
            payload: {
               targetBreakoutId: this.Breakout.id,
               identity: targetParticipant.identity,
               currentBreakoutId: currentBreakout?.id
            }
         }
      });
   }
   validateBreakoutJoin(params: R.Room.DataReceivedParams) {
      //R.Breakout.IdAndIdentityOnly is the payload type
      const { targetBreakoutId, targetParticipant, currentBreakoutId } = validateParams({
         targetBreakoutId: params?.action?.payload?.targetBreakoutId,
         targetParticipant: params?.remoteParticipants?.find((p) => p.identity === params?.action?.payload?.identity),
         currentBreakoutId: params?.action?.payload?.currentBreakoutId
      }) as { targetBreakoutId: string; participants: Participant[]; targetParticipant: Participant; currentBreakoutId: string };
      const state = this.store.getState();
      const targetBreakout = selectBreakoutById(state, targetBreakoutId);

      if (params.senderHost || params.senderCoHost || targetBreakout?.roomOpen || targetBreakout?.mainBreakout) {
         this.transferParticipant(targetParticipant.identity, targetBreakoutId, currentBreakoutId);
      } else {
         throw new Error(`joinBreakout:not allowed to join the breakout ${targetBreakoutId} ${targetBreakout?.roomOpen}`);
      }
   }
   getTrackReferences({
      focusedParticipants,
      focusedRecorder,
      trackReferences
   }: {
      focusedParticipants: RemoteParticipant[] | undefined;
      focusedRecorder: ParticipantMetadata | undefined;
      trackReferences: TrackReference[];
   }): { remoteAudioTracks: TrackReference[]; allowedSubscribers: ParticipantTrackPermission[] } {
      let remoteAudioTracks = [] as TrackReference[];
      let allowedSubscribers = [] as ParticipantTrackPermission[];
      if (!this.localParticipant) return { remoteAudioTracks, allowedSubscribers };
      focusedParticipants?.forEach((p: RemoteParticipant) => {
         const refs = trackReferences.filter((ref) => p.identity === ref.participant.identity);

         refs.forEach((ref: TrackReference) => {
            remoteAudioTracks.push(ref);
         });
         allowedSubscribers.push({
            allowAll: true,
            participantIdentity: p.identity
         });
      });
      if (focusedRecorder && !!focusedRecorder?.egress?.identity) {
         allowedSubscribers.push({
            allowAll: true,
            participantIdentity: focusedRecorder.egress.identity
         });
      }

      return {
         remoteAudioTracks,
         allowedSubscribers
      };
   }
   subscribeTracks({ allowedSubscribers, focusedRemoteParticipants }: { allowedSubscribers: ParticipantTrackPermission[]; focusedRemoteParticipants: RemoteParticipant[] | undefined }) {
      if (allowedSubscribers.length > 0) {
         ILog.v("Breakout", "subscribeTracks_subscribers_allowed", { allowedSubscribers });
         this.localParticipant.setTrackSubscriptionPermissions(false, allowedSubscribers);
      } else {
         ILog.v("Breakout", "subscribeTracks_no_subscribers_allowed", {});
         this.localParticipant.setTrackSubscriptionPermissions(false);
      }

      focusedRemoteParticipants?.forEach((p: RemoteParticipant) => {
         p.trackPublications.forEach((track: RemoteTrackPublication) => {
            track.setEnabled(true);
         });
      });
   }
   receiveEvent(params: R.Room.DataReceivedParams) {
      // validateParams({ params });

      if (params.isSenderFacilitator || params.senderHost) {
         ILog.v("Breakout", "receiveEvent", { action: JSON.stringify(params.action.type), localName: this.localParticipant.name });
         this.dispatch(params.action);
      } else {
         ILog.v("Breakout", "receiveEvent", { action: JSON.stringify(params.action.type), localName: this.localParticipant.name });
         switch (params.action.type) {
            // TODO ZW-2: tighten these up
            case "breakout/addBreakout":
               this.dispatch(params.action);
               break;
            case "breakout/addParticipants":
               this.dispatch(params.action);
               break;
            case "breakout/removeParticipant":
               if (!params?.sender) return;
               if (params.sender.identity === (params.action.payload.identity as R.Breakout.IdAndIdentityOnly["identity"])) {
                  this.dispatch(params.action);
               }
               // else {
               //    throw new Error(`breakout.receiveEvent: not allowed to remove participant  ${JSON.stringify(params)}`);
               // }
               break;
            case "breakout/setSecreenShareIdentity":
               this.dispatch(params.action);
               break;
            default:
               throw new Error(`breakout.receiveEvent unhandled payload type ${params.action.type}`);
         }
      }
   }
   recordingEvent(params: R.Room.DataReceivedParams) {
      validateParams(params);

      const { type, payload: data } = params.action;

      if (params.isSenderRecorder || params.isSenderFacilitator || params.senderHost) {
         if (data?.message) {
            toast(data.message, {
               toastId: data.toastId
            });
         }
      }
   }
   upsert({ breakout, newAgendaItems }: { breakout: R.Breakout.BreakoutRoom; newAgendaItems: R.AgendaItem.AgendaItem[] }) {
      const state = this.store.getState();
      this.sendDataPacket({
         payload: {
            type: "breakout/upsertBreakout",
            payload: breakout
         }
      });
      newAgendaItems.forEach((agendaItem) => {
         if (!agendaItem.clonedFrom) return;
         const clonedItems = selectAgendaItemsByClonedFrom(state, { clonedFrom: agendaItem.clonedFrom, breakoutId: breakout.id });
         if (clonedItems?.length > 0) {
            ILog.v("Breakout", "clonedItems exist", { agendaItem, clonedItems });
            return;
         }
         const existingAgendaItem = selectAgendaItemById(state, agendaItem.clonedFrom);
         const assignedRounds = selectIncompleteRoundsByAgendaItemId(state, existingAgendaItem.id);
         ILog.v("Breakout", "upsert", { agendaItem, assignedRounds, clonedItems, breakout, newAgendaItems });
         this.sendDataPacket({
            payload: {
               type: "agendaItem/upsertAgendaItem",
               payload: agendaItem
            }
         });
         assignedRounds.forEach((round) => {
            this.sendDataPacket({
               payload: {
                  type: "round/upsertRound",
                  payload: {
                     ...round,
                     id: v4(),
                     breakoutId: breakout.id,
                     agendaItemId: agendaItem.id
                  }
               }
            });
         });
      });
   }
}
