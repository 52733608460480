"use client";

import { R } from "app/(platform)/meeting-room/_types";
import { AppStore } from "app/_contexts/ReduxProvider";
import { validateParams } from "app/_helpers/validateParams";
import { Room } from "livekit-client";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { selectBreakoutByParticipantIdentity } from "./breakoutSlice";
import { selectListeningRequestById, selectListeningRequestsByTurnId } from "./listeningRequestSlice";
import LK from "./livekit/livekit";
import { selectIsLocalHost } from "./meetingSlice";
import { selectActiveRound } from "./roundSlice";
import Turn from "./turn";
import { selectIncompleteDependentTurnsAndListeningRequestsBySpeakerIdentity, selectIncompleteTurnByListenerIdentity, selectTurnById } from "./turnSlice";
export default class ListeningRequest extends LK {
   ListeningRequest: Partial<R.ListeningRequest.ListeningRequest>;
   constructor(p: { room: Room; ListeningRequest: Partial<R.ListeningRequest.ListeningRequest>; store: AppStore }) {
      // validateParams(p);
      super(p);
      this.ListeningRequest = p.ListeningRequest;
   }
   exit(exitIdentity: string) {
      validateParams({ exitIdentity });
      const state = this.store.getState();
      const breakout = selectBreakoutByParticipantIdentity(state, exitIdentity);
      const activeRound = selectActiveRound(state, breakout?.id);
      const targetListenerTurn = selectIncompleteTurnByListenerIdentity(state, {
         listenerIdentity: exitIdentity,
         breakoutId: breakout?.id
      });
      // If the disconnected participant is the current listener, then cancelListener and void all LR's
      const turnListeningRequests = selectListeningRequestsByTurnId(state, targetListenerTurn?.id);
      const targetListeningRequest = turnListeningRequests.find((listeningRequest) => listeningRequest.listenerIdentity === exitIdentity);
      if (targetListeningRequest?.listenerIdentity === this.localParticipant.identity) {
         toast.dismiss(targetListeningRequest?.id);
      }

      turnListeningRequests.forEach((listeningRequest) => {
         this.sendDataPacket({
            payload: {
               type: "listeningRequest/voidListeningRequest",
               payload: {
                  id: listeningRequest.id,
                  fallback: listeningRequest
               }
            }
         });
      });
      const { voidListeningRequests } = selectIncompleteDependentTurnsAndListeningRequestsBySpeakerIdentity(state, {
         targetIdentity: exitIdentity,
         breakoutId: activeRound?.breakoutId
      });
      if (voidListeningRequests.length > 0) {
         voidListeningRequests.forEach((listeningRequest) => {
            this.sendDataPacket({
               payload: {
                  type: "listeningRequest/voidListeningRequest",
                  payload: {
                     id: listeningRequest.id,
                     fallback: listeningRequest
                  }
               }
            });

            if (listeningRequest.listenerIdentity === this.localParticipant.identity) {
               toast.dismiss(listeningRequest.id);
            }
         });
      }

      return this;
   }
   voidRemainingListeningRequests(turnId: string) {
      validateParams({ turnId });
      const state = this.store.getState();
      const listeningRequests = selectListeningRequestsByTurnId(state, turnId) as R.ListeningRequest.ListeningRequest[];
      const turn = selectTurnById(state, turnId);
      const acceptedListeningRequests = listeningRequests.filter((lr) => lr.listenerAccepted === true);

      const earliestListeningRequest = acceptedListeningRequests.sort((a, b) => {
         if (a?.timeAccepted && b?.timeAccepted) {
            return a?.timeAccepted - b?.timeAccepted;
         } else {
            return 0;
         }
      })[0];
      //

      const voidableListeningRequests = listeningRequests.filter((lr) => lr.id !== earliestListeningRequest?.id);
      //
      voidableListeningRequests.forEach((lr) => {
         super.sendDataPacket({
            payload: {
               type: "listeningRequest/voidListeningRequest",
               payload: {
                  id: lr.id,
                  listenerIdentity: lr.listenerIdentity,
                  fallback: lr
               }
            }
         });
         if (lr.listenerIdentity === this.localParticipant.identity) {
            toast.dismiss(lr.id);
         }
      });
      /*   const mutableParticipantIdentities = voidableListeningRequests.map(
         (lr) => lr.listenerIdentity
       );
     
       const mutableParticipants = memoizedParticipants.filter((p) =>
         mutableParticipantIdentities.includes(p.identity)
       ); */
      const TurnInstance = new Turn({
         room: this.room,
         Turn: turn as R.Turn.Turn,
         store: this.store
      });

      if (earliestListeningRequest?.listenerIdentity && earliestListeningRequest?.listenerName && turn?.breakoutId) {
         TurnInstance.createTurn({ breakoutId: turn?.breakoutId }, { identity: earliestListeningRequest?.listenerIdentity, name: earliestListeningRequest?.listenerName });
      } else if (listeningRequests[0]?.listenerIdentity && listeningRequests[0]?.listenerName && turn?.breakoutId) {
         TurnInstance.createTurn({ breakoutId: turn?.breakoutId }, { identity: listeningRequests[0]?.listenerIdentity, name: listeningRequests[0]?.listenerName });
      }

      super.sendDataPacket({
         payload: {
            type: "turn/acceptListeningRequest",
            payload: {
               id: turnId,
               listenerIdentity: earliestListeningRequest ? earliestListeningRequest.listenerIdentity : listeningRequests[0].listenerIdentity,
               listenerName: earliestListeningRequest ? earliestListeningRequest.listenerName : listeningRequests[0].listenerName,
               listenerAccepted: true,
               fallback: {
                  id: turnId,
                  listenerIdentity: earliestListeningRequest ? earliestListeningRequest.listenerIdentity : listeningRequests[0].listenerIdentity,
                  listenerName: earliestListeningRequest ? earliestListeningRequest.listenerName : listeningRequests[0].listenerName,
                  listenerAccepted: true,
                  speakerIdentity: earliestListeningRequest ? earliestListeningRequest.speakerIdentity : listeningRequests[0].speakerIdentity,
                  turnComplete: false,
                  turnActive: false,
                  turnPaused: false,
                  intermissionStartTime: null,
                  turnStartTime: null,
                  turnEndTime: null,
                  turnDuration: null,
                  turnPausedTime: null
               }
            }
         }
      });

      return this;
   }

   sendListeningRequest() {
      //
      this.sendDataPacket({
         payload: {
            type: "listeningRequest/addListeningRequest",
            payload: {
               ...this.ListeningRequest,
               fallback: {
                  ...this.ListeningRequest
               }
            }
         }
      });

      return this;
   }

   acceptListeningRequest() {
      // const isLocalFacilitator = selectIsBreakoutFacilitator(state, {
      //    targetIdentity: this.localParticipant.identity,
      //    breakoutId: this.ListeningRequest.breakoutId
      // });
      const state = this.store.getState();
      const isLocalHost = selectIsLocalHost(state.meeting);
      this.sendDataPacket({
         payload: {
            type: "listeningRequest/acceptListeningRequest",
            payload: {
               ...this.ListeningRequest,
               listenerIdentity: this.localParticipant.identity,
               listenerName: this.localParticipant.name,
               listenerAccepted: true,
               timeAccepted: DateTime.now().toMillis(),
               turnId: this.ListeningRequest.turnId,
               void: false,
               fallback: {
                  ...this.ListeningRequest,
                  listenerIdentity: this.localParticipant.identity,
                  listenerName: this.localParticipant.name,
                  listenerAccepted: true,
                  timeAccepted: DateTime.now().toMillis(),
                  turnId: this.ListeningRequest.turnId,
                  void: false, // Is void already false, or can it be included in spread operator?
                  speakerIdentity: this.ListeningRequest.speakerIdentity,
                  speakerName: this.ListeningRequest.speakerName
               }
            }
         }
      });

      if (isLocalHost && this.ListeningRequest.turnId) {
         this.voidRemainingListeningRequests(this.ListeningRequest.turnId);
      }
   }

   declineListeningRequest() {
      const { id } = validateParams({ id: this.ListeningRequest }) as { id: string };
      const state = this.store.getState();

      const listeningRequest = selectListeningRequestById(state, id);

      this.sendDataPacket({
         payload: {
            type: "listeningRequest/declineListeningRequest",
            payload: {
               id: listeningRequest?.id,
               fallback: listeningRequest
            }
         }
      });
   }

   addListeningRequestEvent(params: R.Room.DataReceivedParams) {
      // Role strategy
      validateParams(params);
      const { type: type, payload: data } = params.action;

      this.dispatch(params.action);
      if (params.isSenderFacilitator) {
         // this.listeningRequestAudio(data.listenerIdentity, data);
      }
   }

   acceptListeningRequestEvent(params: R.Room.DataReceivedParams) {
      // Ownership strategy
      validateParams(params);
      const { type: type, payload: data } = params.action;

      this.dispatch(params.action);
      if (params.localHost) {
         this.voidRemainingListeningRequests(data.turnId);
         // this.listeningRequestAudio(params.participants, data);
      }
   }

   declineListeningRequestEvent(params: R.Room.DataReceivedParams) {
      // Ownership strategy
      validateParams(params);
      const { type: type, payload: data } = params.action;

      this.dispatch(params.action);
   }

   voidListeningRequestEvent(params: R.Room.DataReceivedParams) {
      // Role strategy
      validateParams(params);
      const { type: type, payload: data } = params.action;

      if (data.listenerIdentity === this.localParticipant.identity) {
         toast.dismiss(data.id);
      }

      this.dispatch(params.action);
   }
   receiveEvent(params: R.Room.DataReceivedParams) {
      validateParams(params);
      const { type: type, payload: data } = params.action;

      switch (type) {
         case "listeningRequest/addListeningRequest":
            this.addListeningRequestEvent(params);
            break;
         case "listeningRequest/acceptListeningRequest":
            this.acceptListeningRequestEvent(params);
            break;
         case "listeningRequest/declineListeningRequest":
            this.declineListeningRequestEvent(params);
            break;
         case "listeningRequest/voidListeningRequest":
            this.voidListeningRequestEvent(params);
            break;
         case "listeningRequest/addListeningRequests":
         case "listeningRequest/removeListeningRequest":
         case "listeningRequest/updateListeningRequest":
         case "listeningRequest/updateListeningRequests":
         case "listeningRequest/upsertListeningRequest":
         case "listeningRequest/upsertListeningRequests":
            if (params.isSenderFacilitator || params.senderHost) {
               this.dispatch(params.action);
            } else {
               throw new Error(`ListeningRequest.receiveEvent: Unauthorized ${JSON.stringify(params)}`);
            }
            break;
         default:
            throw new Error(`Unknown listeningRequest event type: ${type}`);
      }
   }
}
