// Used on server & client!
import { R } from "app/(platform)/meeting-room/_types";
import { RoomMetadata } from "app/(platform)/meeting-room/_types/metadata";
import ILog from "app/_lib/global/Log";

export default class Metadata {
   static parseRoom({ md }: { md: string }): RoomMetadata {
      try {
         return JSON.parse(md);
      } catch (e) {
         throw new Error("Error parsing room metadata");
      }
   }
   static parseRoles(
      md: RoomMetadata,
      identity: string | undefined,
      currentBreakout?: R.Breakout.BreakoutRoom
   ): {
      isHost: boolean;
      isCoHost: boolean;
      isBanned: boolean;
      isFacilitator: boolean;
   } {
      try {
         const isHost = md.roomHost === identity;
         const isCoHost = md.coHosts.includes(identity || "");
         const isBanned = md.bans.includes(identity || "");
         const isFacilitator = currentBreakout?.facilitatorIdentity === identity;
         return {
            isHost,
            isCoHost,
            isBanned,
            isFacilitator
         };
      } catch (e) {
         ILog.w("Metadata", "parseLocalRoles", { e });
         throw new Error("Error parsing local roles");
         // return {
         //    isLocalHost: false,
         //    isLocalCoHost: false,
         //    isLocalBanned: false,
         //    isLocalFacilitator: false
         // };
      }
   }

   // These are needed for types! Do not remove.
   static defaultRoomMetadata({ stringify }: { stringify: true }): string;
   static defaultRoomMetadata({ stringify }: { stringify?: false }): RoomMetadata;
   static defaultRoomMetadata({ stringify }: { stringify?: boolean }): string | RoomMetadata {
      const md = { roomHost: null, coHosts: [], bans: [], egress: [], ended: false, password: undefined, meetingCampaignId: undefined };
      return stringify ? JSON.stringify(md) : md;
   }

   static generateRoomMetadata(p: {
      metadata: string;
      roomHost?: string;
      coHosts?: { add?: string[]; remove?: string[] };
      bans?: { add?: string[]; remove?: string[] };
      egress?: { add?: R.Room.LivekitEgress[]; end?: string[]; update?: Partial<R.Room.LivekitEgress>[] };
      ended?: boolean;
      password?: string;
      meetingCampaignId?: string;
   }): string {
      ILog.v("Metadata", "generateRoomMetadata1", { p });
      const {
         roomHost: existingRoomHost,
         coHosts: existingCoHosts,
         bans: existingBans,
         egress: existingEgress,
         ended: existingEnded,
         password: existingPassword,
         meetingCampaignId: existingMeetingCampaignId
      } = this.parseRoom({ md: p.metadata });
      const { roomHost: targetRoomHost, coHosts: targetCoHosts, bans: targetBans, egress: targetEgress, ended: targetEnded, password: targetPassword, meetingCampaignId } = p;

      const roomHost = targetRoomHost ?? existingRoomHost;

      let coHosts = existingCoHosts;
      targetCoHosts?.add ? (coHosts = [...coHosts, ...targetCoHosts.add]) : null;
      targetCoHosts?.remove ? (coHosts = coHosts.filter((coHost) => !targetCoHosts.remove?.includes(coHost))) : null;
      coHosts = coHosts.filter((coHost) => coHost !== roomHost);

      let bans = existingBans;
      targetBans?.add ? (bans = [...bans, ...targetBans.add]) : null;
      targetBans?.remove ? (bans = bans.filter((ban) => !targetBans.remove?.includes(ban))) : null;
      bans = bans.filter((ban) => ban !== roomHost);
      let egress = existingEgress;
      targetEgress?.add ? (egress = [...egress, ...targetEgress.add]) : null;
      targetEgress?.end
         ? (egress = egress.map((eg) => {
              return targetEgress.end?.includes(eg.egressID) ? { ...eg } : eg;
           }))
         : null;
      targetEgress?.update
         ? (egress = egress.map((eg) => {
              return targetEgress?.update?.find((ue) => ue.egressID === eg.egressID) ? { ...eg, ...targetEgress.update.find((ue) => ue.egressID === eg.egressID) } : eg;
           }))
         : null;

      const ended = targetEnded ?? existingEnded;
      ILog.v("Metadata", "generateRoomMetadata2", {
         roomHost,
         coHosts,
         bans,
         egress,
         ended,
         password: targetPassword ?? existingPassword,
         meetingCampaignId: meetingCampaignId ?? existingMeetingCampaignId
      });

      return JSON.stringify({ roomHost, coHosts, bans, egress, ended, password: targetPassword ?? existingPassword, meetingCampaignId: meetingCampaignId ?? existingMeetingCampaignId });
   }
}
