"use client";
import { ParticipantPermission } from "@livekit/protocol";

import kickParticipant from "app/(platform)/meeting-room/_actions/kickParticipant";
import Metadata from "app/(platform)/meeting-room/_services/livekit/metadata";
import { R } from "app/(platform)/meeting-room/_types";
import { AppStore } from "app/_contexts/ReduxProvider";
import { validateParams } from "app/_helpers/validateParams";
import ILog from "app/_lib/global/Log";
import { ConnectionState, LocalParticipant, LocalTrackPublication, Participant, RemoteParticipant, RemoteTrackPublication, Room, Track, TrackPublication } from "livekit-client";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter } from "next/navigation";
import { toast } from "react-toastify";
import { ParticipantMetadata } from "../../_types/metadata";
import AgendaItem from "../agendaItem";
import { agendaItemActions } from "../agendaItemSlice";
import Breakout from "../breakout";
import { breakoutActions, selectAllBreakouts, selectBreakoutById, selectBreakoutByParticipantIdentity, selectIsBreakoutFacilitator, selectMainBreakout } from "../breakoutSlice";
import { chatMessageActions } from "../chatMessageSlice";
import ListeningRequest from "../listeningRequest";
import { listeningRequestActions } from "../listeningRequestSlice";
import { meetingActions } from "../meetingSlice";
import Round from "../round";
import { roundActions } from "../roundSlice";
import Turn from "../turn";
import { turnActions } from "../turnSlice";
import LK from "./livekit";

export default class LKEvent extends LK {
   constructor(p: { room: Room; store: AppStore }) {
      super(p);
   }
   leaveMeeting(router: ReturnType<typeof useRouter>, left: boolean, pathname: string, isLocalRecorder: boolean | undefined) {
      validateParams({ router, left });
      this.localParticipant.setCameraEnabled(false);
      this.localParticipant.setMicrophoneEnabled(false);

      if (!isLocalRecorder && (!left || pathname.includes("meeting-room"))) {
         router.push(`/meetings/post-meeting?type=${left ? "left" : "ended"}`);
      } else {
         this.room.disconnect(true).then(() => {
            this.store.dispatch(meetingActions.setLivekitToken(null));
            this.store.dispatch(agendaItemActions.removeAllAgendaItems());
            this.store.dispatch(breakoutActions.removeAllBreakouts());
            this.store.dispatch(turnActions.removeAllTurns());
            this.store.dispatch(listeningRequestActions.removeAllListeningRequests());
            this.store.dispatch(chatMessageActions.removeAllChatMessages());
            this.store.dispatch(roundActions.removeAllRounds());
         });
      }

      ILog.v("leaveMeeting", { left, room: this.room });
      return this;
   }
   onConnectionStateChanged(connectionState: ConnectionState, router: ReturnType<typeof useRouter>) {}
   onDataReceived(router: AppRouterInstance, sender?: Participant, rawPayload?: Uint8Array) {
      const state = this.store.getState();
      const localHost = state.meeting.localHost;
      const room = this.room;
      const decoder = new TextDecoder();
      const strData = decoder.decode(rawPayload);

      const action = JSON.parse(strData) as {
         type: string;
         payload: any;
      };

      if (!this.room.metadata) {
         // ILog.e("onDataReceived_NO_METADATA", { action, sender });
         ILog.v("onDataReceived_NO_METADATA", { action, sender });
         return;
      }

      const metadata = Metadata.parseRoom({ md: this.room.metadata });
      const { isHost: senderHost, isCoHost: senderCoHost } = Metadata.parseRoles(metadata, sender?.identity);

      const isSenderFacilitator = selectIsBreakoutFacilitator(state, {
         targetIdentity: sender?.identity,
         breakoutId: action?.payload?.breakoutId
      });
      const isLocalFacilitator = selectIsBreakoutFacilitator(state, {
         targetIdentity: this.localParticipant.identity,
         breakoutId: action?.payload?.breakoutId
      });
      let remoteParticipants = Array.from(room?.remoteParticipants).map((value: [string: string, p: RemoteParticipant]) => {
         return value[1];
      }) as Participant[];

      const { type: type, payload: payload } = action;

      const params = {
         sender: sender,
         action: action,
         remoteParticipants,
         localHost: localHost,
         isLocalFacilitator: isLocalFacilitator,
         senderHost: senderHost,
         senderCoHost: senderCoHost,
         isSenderFacilitator: isSenderFacilitator,
         //TODO-HP: Deprecated prop
         isSenderRecorder: sender?.permissions?.recorder
      } as R.Room.DataReceivedParams; /* as R.Room.DataReceivedParams<typeof type, any>; */

      if (sender?.permissions?.recorder) {
         ILog.v("onDataReceived_recorder", { action, sender, type });
      } else {
         ILog.v("onDataReceived_recorder_is_not_sender", { action, sender, type });
      }
      ILog.v("onDataReceived_event", { action, sender, type });

      switch (type as string) {
         case "TOGGLE_REMOTE_CAMERA":
            this.cameraEvent(payload?.targetParticipant, payload?.enabled);
            break;
         case "DISPATCH_TOAST":
            this.validateToastEvent(params);
            break;
         case "RECORDING_EVENT":
            const currentBreakout = selectBreakoutByParticipantIdentity(state, this.localParticipant?.identity);

            if (currentBreakout && currentBreakout.id === payload.breakoutId) {
               ILog.v("RECORDING_EVENT", { payload, currentBreakout, localHost, localP: this.localParticipant?.identity });
               new Breakout({ room, Breakout: currentBreakout, store: this.store }).recordingEvent(params);
            }

            break;
         case "REQUEST_MEETING_STATE":
            this.meetingStateRequest(params);
            break;
         case "BREAKOUT_JOIN_REQUEST":
            if (localHost) {
               const breakout = selectBreakoutById(state, payload?.currentBreakoutId);
               if (breakout) {
                  new Breakout({ room, Breakout: breakout, store: this.store }).validateBreakoutJoin(params);
               }
            }
            break;

         case "meeting/remoteAudioMute":
            if (senderCoHost || senderHost || isSenderFacilitator) {
               if ("targetParticiant" in payload) {
               }
               this.audioEvent(payload.targetParticipant, payload.enabled);
            }
            break;

         case "meeting/remoteCameraMute":
            if (senderCoHost || senderHost || isSenderFacilitator) {
               this.cameraEvent(payload.targetParticipant, payload.enabled);
            }
            break;
         case "meeting/setViewMode":
            if (senderCoHost || senderHost || isSenderFacilitator) {
               this.simpleEvent(params);
            }
            break;
         case "agendaItem/addAgendaItem":
         case "agendaItem/addAgendaItems":
         case "agendaItem/endAgendaItem":
         case "agendaItem/updateAgendaItem":
         case "agendaItem/removeAgendaItem":
         case "agendaItem/pauseAgendaItem":
         case "agendaItem/resumeAgendaItem":
         case "agendaItem/startAgendaItem":
         case "agendaItem/upsertAgendaItem":
         case "agendaItem/upsertAgendaItems":
            new AgendaItem({ room, AgendaItem: payload, store: this.store }).receiveEvent(params);
            // if (room) {
            // } else if (senderHost) {
            //    this.simpleEvent(params);
            // } else {
            // }
            break;
         case "breakout/addBreakout":
         case "breakout/addBreakouts":
         case "breakout/addParticipant":
         case "breakout/addParticipants":
         case "breakout/endBreakout":
         case "breakout/pauseAgenda":
         case "breakout/removeBreakout":
         case "breakout/removeParticipant":
         case "breakout/resumeAgenda":
         case "breakout/setActive":
         case "breakout/setBreakouts":
         case "breakout/setScreenShareIdentity":
         case "breakout/setBreakoutFacilitator":
         case "breakout/startAgenda":
         case "breakout/startBreakout":
         case "breakout/updateBreakout":
         case "breakout/updateBreakouts":
         case "breakout/upsertBreakout":
         case "breakout/upsertBreakouts":
         case "breakout/setClosed":
         case "breakout/setScreenSyncPath":
            new Breakout({ room, Breakout: {}, store: this.store }).receiveEvent(params);
            // if (room ) {
            // } else if (senderHost) {
            //    this.simpleEvent(params);
            // }
            break;
         case "chatMessage/addChatMessage":
         case "chatMessage/updateOneChatMessage":
         case "chatMessage/removeChatMessage":
         case "chatMessage/upsertChatMessages":
         case "chatMessage/updateManyChatMessages":
            this.dangerous_simpleEvent(params);
            break;
         case "listeningRequest/addListeningRequests":
         case "listeningRequest/removeListeningRequest":
         case "listeningRequest/updateListeningRequest":
         case "listeningRequest/addListeningRequest":
         /*             LR.validateAddListeningRequest(params);
            break; */
         case "listeningRequest/acceptListeningRequest":
         /*             LR.validateAcceptListeningRequest(params);
            break; */
         case "listeningRequest/declineListeningRequest":
         /*             LR.validateDeclineListeningRequest(params);
            break; */
         case "listeningRequest/voidListeningRequest":
         case "listeningRequest/upsertListeningRequest":
         case "listeningRequest/upsertListeningRequests":
            // LR.validateVoidListeningRequest(params);
            listeningRequestActions.acceptListeningRequest.match(action);
            new ListeningRequest({ room, ListeningRequest: payload, store: this.store }).receiveEvent(params);
            // if (room ) {
            // } else if (senderHost) {
            //    this.simpleEvent(params);
            // }

            break;
         case "meeting/setMeetingPassword":
            if (senderHost) {
               this.simpleEvent(params);
               ILog.v("meeting/setMeetingPassword", { payload });
               router.replace(`/meeting-room?password=${payload}`);
            }
            break;
         case "meeting/adjournMeeting":
         case "meeting/updateMeeting":
         case "meeting/setEmpathyCafe":
         case "meeting/setWorkspaceInviteToken":
            if (senderHost) {
               this.simpleEvent(params);
            }
            break;
         case "round/addRound":
         case "round/addRounds":
         case "round/endRound":
         case "round/pauseRound":
         case "round/removeRound":
         case "round/resumeRound":
         case "round/startRound":
         case "round/updateRound":
         case "round/upsertRounds":
         case "round/upsertRound":
            // if (room) {
            new Round({ room, Round: payload, store: this.store }).receiveEvent(params);
            // } else if (senderHost) {
            //    this.simpleEvent(params);
            // }
            break;
         case "turn/acceptListeningRequest":
         /*    LR.validateAcceptListeningRequestTurnEvent(params);
               break; */
         case "turn/addTurn":
         /*    new Turn({ room, Turn: data.fallback as R.Turn.Turn }).createTurn();
            Turn.validateAddTurnEvent(params);
            break; */
         case "turn/addTurns":
         case "turn/cancelListener":
         //  case "turn/confirmTurn":
         case "turn/endTurn":
         /* Turn.validateEndTurnEvent(params);
            break; */
         case "turn/pauseTurn":
         case "turn/queueTurn":
         case "turn/resumeTurn":
         case "turn/removeTurn":
         case "turn/startTurn":
         case "turn/updateTurn":
         case "turn/voidTurn":
         case "turn/upsertTurn":
         case "turn/upsertTurns":
            new Turn({ room, Turn: payload, store: this.store }).receiveEvent(params);
            // if (room) {
            // } else if (senderHost) {
            //    this.simpleEvent(params);
            // }
            break;

         default:
            throw new Error(`Unhandled action type onDataReceived ${action?.type}`);
      }
   }
   onActiveSpeakersChanged(room: Room, participants: Participant[]) {
      const state = this.store.getState();
      const breakout = selectBreakoutByParticipantIdentity(state, this.localParticipant?.identity);
      ILog.v("onActiveSpeakersChanged", { participants });
      //const participantRole = JSON.parse(room?.localParticipant?.metadata || "{}")?.role === "host";
      if (breakout) {
         const metadata = Metadata.parseRoom({ md: room.metadata! });

         const breakoutHostCoHosts = breakout.participants.filter((p) => {
            const { isHost, isCoHost } = Metadata.parseRoles(metadata, p);
            return isHost || isCoHost;
         });
         const activeHost = breakoutHostCoHosts[0];
         const activeSpeaker = participants.find((p) => breakout.participants.find((bp) => bp === p.identity));
         /*    const currentTurn = selectActiveTurn(state, breakout.id); */
         /*      const nextTurn = selectIncompleteTurnBySpeakerIdentity(state, {
              speakerIdentity: currentTurn?.listenerIdentity,
              breakoutId: breakout.id
           }); */

         ILog.v("activeSpeaker", { activeSpeaker });
         if (activeSpeaker) {
            this.dispatch({
               type: "breakout/setActiveSpeakerIdentity",
               payload: {
                  id: breakout.id,
                  identity: activeSpeaker.identity
               }
            });
         }
         if (activeHost && breakout?.activeHostIdentity !== activeHost) {
            this.dispatch({
               type: "breakout/setActiveHostIdentity",
               payload: {
                  id: breakout.id,
                  identity: activeHost
               }
            });
         }
      }
   }
   onParticipantConnected(connectedParticipant: RemoteParticipant) {
      const state = this.store.getState();
      const meeting = state.meeting;

      const mainBreakout = selectMainBreakout(state);
      if (!this.room.metadata) {
         ILog.v("onParticipantConnected_NO_METADATA", { connectedParticipant, mainBreakout });
         return;
      }
      const metadata = Metadata.parseRoom({ md: this.room.metadata });
      const { isBanned } = Metadata.parseRoles(metadata, connectedParticipant.identity);

      const participantMetadata = JSON.parse(connectedParticipant.metadata || "{}") as ParticipantMetadata;

      ILog.v("onParticipantConnected", { connectedParticipant, mainBreakout, metadata });
      if (meeting.localHost) {
         if (participantMetadata?.role === "recorder" && participantMetadata?.egress?.breakoutId) {
            const recorderBreakout = selectBreakoutById(state, participantMetadata.egress.breakoutId);
            if (!recorderBreakout) {
               ILog.v("onParticipantConnected_NO_RECORDER_BREAKOUT", { connectedParticipant, participantMetadata });
            }
            ILog.v("onParticipantConnected_RECORDER", { connectedParticipant, participantMetadata, recorderBreakout, allBreakouts: selectAllBreakouts(state) });
            setTimeout(() => {
               this.sendMeetingState([connectedParticipant]);
               new Breakout({ room: this.room, Breakout: recorderBreakout, store: this.store }).defaultBreakout({ connectedParticipant, activeEgress: true, breakoutId: recorderBreakout.id });
            }, 5000);
         } else if (!isBanned && mainBreakout) {
            setTimeout(() => {
               this.sendMeetingState([connectedParticipant]);
               new Breakout({ room: this.room, Breakout: mainBreakout, store: this.store }).defaultBreakout({ connectedParticipant, activeEgress: false, breakoutId: mainBreakout.id });
            }, 5000);
         } else if (isBanned) {
            kickParticipant({ livekitToken: sessionStorage.livekitToken, targetIdentity: connectedParticipant.identity });
            ILog.w("WARN: onParticipantConnected_BANNED", { connectedParticipant, mainBreakout });
         } else {
            ILog.e("onParticipantConnected_NO_MAIN_BREAKOUT", { connectedParticipant, mainBreakout });
         }
      }
   }
   onParticipantPermissionsChanged(prevPermissions: ParticipantPermission | undefined, changedParticipant: LocalParticipant | RemoteParticipant) {
      //
      if (changedParticipant.identity === this.localParticipant?.identity) {
         const canNowPublish = prevPermissions?.canPublish === false && changedParticipant?.permissions?.canPublish === true;
         //

         if (canNowPublish) {
            ILog.v("onParticipantPermissionsChanged", { canNowPublish, name: changedParticipant.name });
            this.sendDataPacket({
               payload: {
                  type: "REQUEST_MEETING_STATE",
                  payload: {}
               }
            });
         }
      }
   }
   onRoomMetadataChanged(rawMetadata: string, router: ReturnType<typeof useRouter>, params: string, isLocalRecorder: boolean | undefined) {
      const newMetadata = Metadata.parseRoom({ md: rawMetadata });
      const recorders = newMetadata?.egress ?? [];

      const meeting = this.store.getState().meeting;
      const { localHost, localCoHost } = meeting;

      const { isHost, isCoHost } = Metadata.parseRoles(newMetadata, this.localParticipant.identity);
      ILog.v("onRoomMetadataChanged", { newMetadata, localHost, localCoHost, isHost, isCoHost });

      if ((isHost && !localHost) || (!isHost && localHost)) {
         this.dispatch({
            type: "meeting/updateLocalHost",
            payload: isHost ? true : false
         });
      }
      if ((isCoHost && !localCoHost) || (!isCoHost && localCoHost)) {
         this.dispatch({
            type: "meeting/updateLocalCoHost",
            payload: isCoHost ? true : false
         });
      }

      if (newMetadata?.ended) {
         this.leaveMeeting(router, false, params, isLocalRecorder);
      }
   }
   onParticipantMetadataChanged(prevMetadata: string | undefined, changedParticipant: LocalParticipant | RemoteParticipant) {
      const metadata = JSON.parse(changedParticipant?.metadata || "{}");
      const isLocalParticipant = changedParticipant?.identity === this.localParticipant.identity;
      ILog.v("onParticipantMetadataChanged", { metadata, isLocalParticipant });
   }
   onTrackMuted(track: TrackPublication) {
      validateParams({ track });
      //
      if (track instanceof LocalTrackPublication) {
         if (track.videoTrack) {
            //
            this.dispatch({
               type: "meeting/setVideoEnabled",
               payload: false
            });
         } else {
            //
            this.dispatch({
               type: "meeting/setAudioEnabled",
               payload: false
            });
         }
      }
   }
   onTrackPublished(publication: RemoteTrackPublication, participant: Participant) {
      const state = this.store.getState();
      publication.setSubscribed(true);
      if (publication.source === Track.Source.ScreenShare) {
         const breakout = selectBreakoutByParticipantIdentity(state, participant?.identity);
         const localBreakout = selectBreakoutByParticipantIdentity(state, this.localParticipant?.identity);
         // const localShare = breakout?.screenShareIdentity === this.localParticipant?.identity;
         const localShare = this.localParticipant.isScreenShareEnabled && breakout?.screenShareIdentity === this.localParticipant?.identity;
         if (localShare && participant.identity !== this.localParticipant?.identity) {
            this.localParticipant?.setScreenShareEnabled(false);
         }
         //
         this.dispatch({
            type: "breakout/setScreenShareIdentity",
            payload: {
               id: breakout?.id,
               identity: participant?.identity
            }
         });
         if (localBreakout?.id === breakout?.id) {
            this.dispatch({
               type: "meeting/setViewMode",
               payload: "spotlight"
            });
         }
         //  publication.setVideoQuality();
      }
      if (publication.source === Track.Source.ScreenShareAudio) {
      }
   }
   onTrackUnmuted(track: TrackPublication, unmutedParticipant: Participant) {
      //
      /* if(track instanceof RemoteTrackPublication) {
         track.setEnabled(true);
      } */
   }
   onTrackUnPublished(publication: RemoteTrackPublication, participant: Participant) {
      const state = this.store.getState();
      publication.setSubscribed(false);

      if (publication.source === Track.Source.ScreenShare) {
         const breakout = selectBreakoutByParticipantIdentity(state, participant.identity);
         const localBreakout = selectBreakoutByParticipantIdentity(state, this.localParticipant?.identity);
         if (breakout?.id === localBreakout?.id) {
            this.dispatch({
               type: "meeting/setViewMode",
               payload: "grid"
            });

            if (breakout?.screenShareIdentity === participant.identity) {
               this.dispatch({
                  type: "breakout/setScreenShareIdentity",
                  payload: {
                     id: breakout?.id,
                     identity: undefined
                  }
               });
            }
         }
      }
   }
   onParticipantDisconnected(disconnectedParticipant: Participant, participants: Participant[]) {
      validateParams({ disconnectedParticipant, participants });
      const state = this.store.getState();
      const localHost = state.meeting.localHost;
      const currentBreakout = selectBreakoutByParticipantIdentity(state, this.localParticipant?.identity);
      ILog.v("onParticipantDisconnected", { disconnectedParticipant, participants });
      //
      //  const participantRole = JSON.parse(disconnectedParticipant?.metadata || "{}")?.role === "host";
      if (currentBreakout) {
         new Breakout({ room: this.room, Breakout: {}, store: this.store }).exit(disconnectedParticipant.identity, currentBreakout?.id);
      }

      // Warning: If the disconnecting client is localHost, then they will freeze onParticipantDisconnected.
      if (localHost) {
      } else {
         //
      }
   }
   validateToastEvent(params: R.Room.DataReceivedParams) {
      const { type: type, payload: data } = params.action;
      const effectCase = (): string => {
         return "toast_event";
      };

      switch (effectCase()) {
         case "toast_event":
            if (params.senderHost || params.senderCoHost || params.isSenderFacilitator) {
               toast(data.message, {
                  type: data.toastType,
                  autoClose: data.autoClose,
                  hideProgressBar: data.hideProgressBar,
                  closeOnClick: data.closeOnClick,
                  pauseOnHover: data.pauseOnHover,
                  draggable: data.draggable,
                  progress: data.progress,
                  position: data.position,
                  theme: data.theme,
                  toastId: data.toastId,
                  onClose: data.onClose,
                  onOpen: data.onOpen,
                  onClick: data.onClick,
                  className: data.className,
                  progressClassName: data.progressClassName,
                  closeButton: data.closeButton,
                  transition: data.transition,
                  rtl: data.rtl,
                  style: data.style
               });
            } else {
            }
            break;
         default:
            return;
      }
   }
   meetingStateRequest(params: R.Room.DataReceivedParams) {
      if (params.localHost) {
         if (params.sender) {
            this.sendMeetingState([params.sender]);
         } else {
            ILog.v("meetingStateRequest_has_no_sender", { params });
         }
      }
   }
}
