"use client";
import { R } from "app/(platform)/meeting-room/_types";
import { AppStore } from "app/_contexts/ReduxProvider";
import { validateParams } from "app/_helpers/validateParams";
import ILog from "app/_lib/global/Log";
import { appSlice } from "app/_services/redux/slices/appSlice";
import { Room } from "livekit-client";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { agendaItemActions, selectActiveAgendaItem, selectNextAgendaItem } from "./agendaItemSlice";
import { breakoutActions, selectAllBreakouts } from "./breakoutSlice";
import LK from "./livekit/livekit";
import { meetingActions } from "./meetingSlice";
import Round from "./round";

export default class AgendaItem extends LK {
   AgendaItem: Partial<R.AgendaItem.AgendaItem>;
   constructor(p: { room: Room; AgendaItem: Partial<R.AgendaItem.AgendaItem>; store: AppStore }) {
      // validateParams(p);
      super(p);
      this.AgendaItem = p.AgendaItem;
   }
   close(breakoutId: string | undefined) {
      validateParams({ breakoutId });
      const state = this.store.getState();

      const activeAgendaItem = selectActiveAgendaItem(state, breakoutId);

      if (activeAgendaItem) {
         this.sendDataPacket({
            payload: {
               type: "agendaItem/pauseAgendaItem",
               payload: {
                  id: activeAgendaItem.id,
                  fallback: { ...activeAgendaItem, itemPaused: true }
               }
            }
         });
      }

      return this;
   }
   outputCaluation(event: any) {
      event.preventDefault();
      const inputValue = event.target.value;
      const eventForm = event.target.form;

      const selectId = event.target.id;
      const index = selectId.split(".")[0];
      const selectType = selectId.split(".")[1];

      if (selectType === "selectSpeakerMinutes") {
         const siblingSelectId = `${index}.selectSpeakerSeconds`; // get the id of the sibling select element
         const selectSpeakerSeconds = eventForm[siblingSelectId].value; // get the sibling select element
         const output = inputValue * 60 + selectSpeakerSeconds * 1;

         return output;
      } else if (selectType === "selectSpeakerSeconds") {
         const siblingSelectId = `${index}.selectSpeakerMinutes`; // get the id of the sibling select element
         const selectSpeakerMinutes = eventForm[siblingSelectId].value; // get the sibling select element
         const output = inputValue * 1 + selectSpeakerMinutes * 60;

         return output;
      } else if (selectType === "selectIntermissionMinutes") {
         const siblingSelectId = `${index}.selectIntermissionSeconds`; // get the id of the sibling select element
         const selectIntermissionSeconds = eventForm[siblingSelectId].value; // get the sibling select element
         const output = inputValue * 60 + selectIntermissionSeconds * 1;

         return output;
      } else if (selectType === "selectIntermissionSeconds") {
         const siblingSelectId = `${index}.selectIntermissionMinutes`; // get the id of the sibling select element
         const selectIntermissionMinutes = eventForm[siblingSelectId].value; // get the sibling select element
         const output = inputValue * 1 + selectIntermissionMinutes * 60;

         return output;
      }
   }

   inputCalculation(value?: number) {
      if (value) {
         const minutes = Math.floor(value / 60);
         const seconds = value % 60;

         return { minutes, seconds };
      } else {
         const minutes = Math.floor(300 / 60);
         const seconds = 300 % 60;

         return { minutes, seconds };
      }
   }
   startEmpathyCafe() {
      const allBreakouts = selectAllBreakouts(this.store.getState());
      this.sendDataPacket({
         payload: meetingActions.setEmpathyCafe(true)
      });

      allBreakouts.forEach((breakout) => {
         const id = v4();

         this.sendDataPacket({
            payload: agendaItemActions.addAgendaItem({
               id: id,
               breakoutId: breakout.id,
               title: "Empathy Cafe",
               empathyCafe: true,
               empathyCafeConfig: {
                  empathyMode: "reflective",
                  strictTime: false,
                  speakerTime: 180,
                  intermissionTime: 10,
                  agendaItemId: id
               },
               description: "",
               itemComplete: false,
               itemActive: true,
               itemPaused: false,
               presenterTime: 0,
               totalTime: 0,
               sort: 0,
               created: DateTime.now().toMillis(),
               void: false
            })
         });

         this.sendDataPacket({
            payload: breakoutActions.startAgenda({ id: breakout.id })
         });
         ILog.v("startEmpathyCafe", { breakoutId: breakout.id, id, allBreakouts });
      });

      this.dispatch(appSlice.actions.setAllMenus({ agendaNavOpen: true, leftNavOpen: true }));
      this.dispatch(meetingActions.setEmpathyCafeModalOpen(true));
      return this;
   }

   adjournMeeting(breakoutId: string) {
      validateParams({ breakoutId });

      this.endAgendaItem(breakoutId);

      this.sendDataPacket({
         payload: {
            type: "meeting/adjournMeeting",
            payload: true
         }
      });

      this.dispatch({
         type: "app/setAllMenus",
         payload: {
            agendaMenuOpen: true
         }
      });

      return this;
   }

   endAgendaItem(breakoutId: string) {
      validateParams({ breakoutId });
      const state = this.store.getState();

      const activeAgendaItem = selectActiveAgendaItem(state, breakoutId);

      new Round({
         room: this.room,
         Round: {},
         store: this.store
      }).endRound(breakoutId);

      if (activeAgendaItem) {
         this.sendDataPacket({
            payload: {
               type: "agendaItem/endAgendaItem",
               payload: {
                  id: activeAgendaItem.id,
                  fallback: activeAgendaItem
               }
            }
         });
      }

      return this;
   }

   startAgenda(breakoutId: string) {
      const state = this.store.getState();

      const startableAgendaItem = selectNextAgendaItem(state, breakoutId);

      ILog.v("startAgenda", { breakoutId, startableAgendaItem });
      this.sendDataPacket({
         payload: {
            type: "breakout/startAgenda",
            payload: {
               id: breakoutId
            }
         }
      });

      if (startableAgendaItem) {
         this.sendDataPacket({
            payload: {
               type: "agendaItem/startAgendaItem",
               payload: {
                  id: startableAgendaItem?.id,
                  fallback: startableAgendaItem
               }
            }
         });
      } else {
         // throw new Error("No startable agenda item found");
      }
      return this;
   }

   startAgendaItem(breakoutId: string) {
      const state = this.store.getState();

      const startableAgendaItem = selectNextAgendaItem(state, breakoutId);

      if (!startableAgendaItem) throw new Error("No startable agenda item found");

      this.endAgendaItem(breakoutId);

      this.sendDataPacket({
         payload: {
            type: "agendaItem/startAgendaItem",
            payload: {
               id: startableAgendaItem?.id,
               fallback: startableAgendaItem
            }
         }
      });

      return this;
   }
   receiveEvent(params: R.Room.DataReceivedParams) {
      if (params.isSenderFacilitator || params.senderHost) {
         ILog.v("AgendaItem", "receiveEvent", { action: JSON.stringify(params.action), localName: this.localParticipant.name });
         this.dispatch(params.action);
      } else {
         throw new Error(`AgendaItem.receiveEvent: Unauthorized ${JSON.stringify(params)}`);
      }
   }
}
