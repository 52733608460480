"use client";

import { R } from "app/(platform)/meeting-room/_types";
import { AppStore } from "app/_contexts/ReduxProvider";
import { validateParams } from "app/_helpers/validateParams";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { Room } from "livekit-client";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { selectBreakoutById, selectBreakoutByParticipantIdentity, selectIsBreakoutFacilitator } from "./breakoutSlice";
import { selectListeningRequestsByTurnId } from "./listeningRequestSlice";
import LK from "./livekit/livekit";
import Round from "./round";
import { selectActiveRound, selectActiveRoundByBreakoutId } from "./roundSlice";
import {
   selectActiveRoundTurnBySpeakerIdentity,
   selectActiveTurn,
   selectFirstTurnClaimsByTimeSort,
   selectIncompleteAndInactiveTurnsByRoundId,
   selectIncompleteDependentTurnsAndListeningRequestsBySpeakerIdentity,
   selectIncompleteTurnByListenerIdentity,
   selectIncompleteTurnBySpeakerIdentity,
   selectIncompleteTurnsOfActiveRoundByTimeSort,
   selectParticipantsWhoDoNotHaveATurn,
   selectReflectiveTurnBySpeakerIdentity,
   selectTurnsByRoundId
} from "./turnSlice";
export default class Turn extends LK {
   Turn: Partial<R.Turn.Turn>;
   constructor(p: { room: Room; Turn: Partial<R.Turn.Turn>; store: AppStore }) {
      //  validateParams(p);
      super(p);
      this.Turn = p.Turn;
   }

   createTurn = (p: { breakoutId: string | undefined }, speaker?: { identity?: string; name?: string }) => {
      validateParams(p);
      const { breakoutId } = p;
      const { identity: speakerIdentity, name: speakerName } = speaker ? speaker : this.localParticipant;
      const state = this.store.getState();
      const activeRound = selectActiveRound(state, breakoutId);
      const activeRoundTurns = selectTurnsByRoundId(state, activeRound?.id);
      const activeRoundTurn = selectActiveRoundTurnBySpeakerIdentity(state, { speakerIdentity, breakoutId });
      const activeTurn = selectActiveTurn(state, breakoutId);
      const { intermissionStartTime, turnStartTime, turnEndTime, returnedNow } = this.createTurnTime(activeRound);
      const isLocalFacilitator = selectIsBreakoutFacilitator(state, {
         targetIdentity: this.localParticipant.identity,
         breakoutId: breakoutId
      });

      const firstTurn = activeRound?.roundActive && !activeRoundTurns.length;

      if (!activeRoundTurn) {
         const id = v4();
         this.sendDataPacket({
            payload: {
               type: "turn/addTurn",
               payload: {
                  id: id,
                  roundId: activeRound?.id,
                  breakoutId: breakoutId,
                  speakerIdentity: speakerIdentity,
                  speakerName: speakerName,
                  listenerIdentity: null,
                  listenerName: null,
                  listenerAccepted: false,
                  speakerHeard: null,
                  nextListener: null,
                  facilitator: null,
                  turnComplete: false,
                  turnActive: activeTurn ? false : true,
                  turnPaused: false,
                  firstTurn: firstTurn ? true : false,
                  intermissionStartTime: activeRound?.strictTime ? intermissionStartTime : null,
                  turnStartTime: activeRound?.strictTime ? turnStartTime : null,
                  turnEndTime: activeRound?.strictTime ? turnEndTime : null,
                  turnDuration: null,
                  pausedTime: null,
                  turnClaimTime: returnedNow,
                  void: false,
                  fallback: {
                     id: id,
                     roundId: activeRound?.id,
                     breakoutId: breakoutId,
                     speakerIdentity: speakerIdentity,
                     speakerName: speakerName,
                     listenerIdentity: null,
                     listenerName: null,
                     listenerAccepted: false,
                     speakerHeard: null,
                     nextListener: null,
                     facilitator: null,
                     turnComplete: false,
                     turnActive: activeTurn ? false : true,
                     turnPaused: false,
                     firstTurn: firstTurn ? true : false,
                     intermissionStartTime: activeRound?.strictTime ? intermissionStartTime : null,
                     turnStartTime: activeRound?.strictTime ? turnStartTime : null,
                     turnEndTime: activeRound?.strictTime ? turnEndTime : null,
                     turnDuration: null,
                     pausedTime: null,
                     turnClaimTime: returnedNow,
                     void: false
                  }
               }
            }
         });

         if (!firstTurn && speakerIdentity === this.localParticipant.identity) {
            this.store.dispatch({
               type: "app/setAllMenus",
               payload: { leftNavOpen: true, listeningRequestNavOpen: true }
            });
         }
      }
      if (isLocalFacilitator) {
         this.voidRemainingFirstTurns();
      }
   };
   voidRemainingFirstTurns = () => {
      const { breakoutId } = this.Turn;
      const state = this.store.getState();
      const activeRound = selectActiveRoundByBreakoutId(state, breakoutId);
      const firstTurnClaims = selectFirstTurnClaimsByTimeSort(state, { roundId: activeRound?.id, breakoutId: breakoutId });
      const firstTurnClaim = firstTurnClaims[0] as R.Turn.Turn;

      const voidTurns = Object.values(firstTurnClaims).filter((turn) => turn?.id !== firstTurnClaim.id);

      /*   if (firstTurnClaim && firstTurnClaim?.id) {
         this.sendDataPacket({
            type: "turn/confirmTurn",
            payload: {
               id: firstTurnClaim.id,
               fallback: firstTurnClaim
            }
         });
      } */
      /* if (firstTurnClaim && firstTurnClaim.speakerIdentity === localParticipant.identity) {
           openSelectListenerModal();
        } */

      if (voidTurns.length > 0) {
         voidTurns.forEach((turn) => {
            this.sendDataPacket({
               payload: {
                  type: "turn/voidTurn",
                  payload: {
                     id: turn?.id,
                     fallback: turn
                  }
               }
            });
         });
      }
   };

   startTimer(activeRound: R.Round.Round) {
      const { intermissionStartTime, turnStartTime, turnEndTime } = this.createTurnTime(activeRound);

      this.sendDataPacket({
         payload: {
            type: "turn/startTurn",
            payload: {
               id: this.Turn.id,
               breakoutId: this.Turn.breakoutId,
               turnStartTime: turnStartTime,
               turnEndTime: turnEndTime,
               intermissionStartTime: intermissionStartTime,
               fallback: {
                  ...this.Turn,
                  id: this.Turn.id,
                  breakoutId: this.Turn.breakoutId,
                  turnStartTime: turnStartTime,
                  turnEndTime: turnEndTime,
                  intermissionStartTime: intermissionStartTime
               }
            }
         }
      });
   }

   pauseTimer(activeRound: R.Round.Round) {
      const { returnedNow } = this.createTurnTime(activeRound);

      this.sendDataPacket({
         payload: {
            type: "turn/pauseTurn",
            payload: {
               id: this.Turn.id,
               turnPaused: true,
               turnPausedTime: returnedNow,
               turnResumeTime: undefined,
               fallback: {
                  ...this.Turn,
                  id: this.Turn.id,
                  turnPaused: true,
                  turnPausedTime: returnedNow,
                  turnResumeTime: undefined
               }
            }
         }
      });
   }

   resumeTimer(/* activeTurn: R.Turn.Turn */) {
      /* validateParams({ activeTurn }); */
      const activeTurn = this.Turn;
      const { intermissionStartTime, turnStartTime, turnEndTime } = this.resumeTurnTime();

      this.sendDataPacket({
         payload: {
            type: "turn/resumeTurn",
            payload: {
               id: activeTurn.id,
               turnStartTime: turnStartTime,
               turnEndTime: turnEndTime,
               intermissionStartTime: intermissionStartTime,
               turnPaused: false,
               turnPausedTime: null,
               turnResumeTime: null,
               fallback: {
                  ...activeTurn,
                  id: activeTurn.id,
                  turnStartTime: turnStartTime,
                  turnEndTime: turnEndTime,
                  intermissionStartTime: intermissionStartTime,
                  turnPaused: false,
                  turnPausedTime: null,
                  turnResumeTime: null
               }
            }
         }
      });
   }
   endTurn() {
      const state = this.store.getState();
      const isLocalFacilitator = selectIsBreakoutFacilitator(state, { breakoutId: this.Turn.breakoutId, targetIdentity: this.localParticipant.identity });
      const activeRound = selectActiveRound(state, this.Turn.breakoutId);
      if (activeRound?.empathyMode === "reflective" && this.Turn.listenerAccepted !== true) {
         this.sendDataPacket({
            payload: {
               type: "turn/voidTurn",
               payload: {
                  id: this.Turn.id,
                  breakoutId: this.Turn.breakoutId,
                  fallback: this.Turn
               }
            }
         });
      }
      this.sendDataPacket({
         payload: {
            type: "turn/endTurn",
            payload: {
               id: this.Turn.id,
               breakoutId: this.Turn.breakoutId,
               fallback: this.Turn
            }
         }
      });
      if (isLocalFacilitator) {
         this.remainingTurns();
      }
      return this;
   }
   exit(exitIdentity: string) {
      const state = this.store.getState();
      const breakout = selectBreakoutByParticipantIdentity(state, exitIdentity);
      const activeRound = selectActiveRound(state, breakout?.id);
      const targetSpeakerTurn = selectIncompleteTurnBySpeakerIdentity(state, {
         speakerIdentity: exitIdentity,
         breakoutId: breakout?.id
      });
      const targetListenerTurn = selectIncompleteTurnByListenerIdentity(state, {
         listenerIdentity: exitIdentity,
         breakoutId: breakout?.id
      });
      if (activeRound?.empathyMode === "reflective") {
         if (targetListenerTurn) {
            this.sendDataPacket({
               payload: {
                  type: "turn/cancelListener",
                  payload: {
                     id: targetListenerTurn.id,
                     fallback: targetListenerTurn
                  }
               }
            });
         } else if (targetSpeakerTurn) {
            if (targetSpeakerTurn.listenerAccepted) {
               new Turn({
                  room: this.room,
                  Turn: targetSpeakerTurn,
                  store: this.store
               }).endTurn();
            } else {
               const { voidTurns } = selectIncompleteDependentTurnsAndListeningRequestsBySpeakerIdentity(state, {
                  targetIdentity: exitIdentity,
                  breakoutId: activeRound.breakoutId
               });
               if (voidTurns.length > 0) {
                  voidTurns.forEach((turn) => {
                     this.sendDataPacket({
                        payload: {
                           type: "turn/voidTurn",
                           payload: {
                              id: turn.id,
                              fallback: turn
                           }
                        }
                     });
                  });
               }
            }
         }
      }
      if (activeRound?.empathyMode === "active" && targetSpeakerTurn) {
         this.sendDataPacket({
            payload: {
               type: "turn/voidTurn",
               payload: {
                  id: targetSpeakerTurn.id,
                  fallback: targetSpeakerTurn
               }
            }
         });
      }

      return this;
   }
   /* 
   
   Should localHost or localFacilitator run remainingTurns?

   When breakout.facilitator is undefined, how should it be handled?
      Automatically assigning facilitator
      Fallback to localHost
       isLocalFacilitator ||  (!breakoutFacilitator && localHost)

   If all clients actually run turn/endTurn, then must validate that the client ending the turn owns the turn.
   
   Deprecate turn/queueTurn in favor of turn/startTurn, and just not pass time properties to it.

   */
   remainingTurns() {
      const { breakoutId, id } = validateParams({ breakoutId: this.Turn.breakoutId, id: this.Turn.id }) as { breakoutId: string; id: string };

      const state = this.store.getState();
      const breakoutRoom = selectBreakoutById(state, breakoutId);
      // if localParticipant is host:
      const localHost = state.meeting.localHost;
      const remainingTurns = this.hasRemainingTurns(breakoutId);
      const activeRound = selectActiveRound(state, breakoutId);
      const activeTurn = selectActiveTurn(state, breakoutId);
      // Not sure why we used to throw this error
      //  if (activeTurn?.id !== id) throw new Error(`Turn.remainingTurns: activeTurn?.id: ${activeTurn?.id} !== turnId: ${id}`);
      // const nextSpeakerIdentity = selectNextSpeaker(state);
      const nextReflectiveTurn = selectReflectiveTurnBySpeakerIdentity(state, {
         speakerIdentity: activeTurn?.listenerIdentity || this?.Turn?.listenerIdentity || undefined,
         breakoutId: breakoutId
      });

      const activeListeningTurns = selectIncompleteTurnsOfActiveRoundByTimeSort(state, breakoutId);
      const remainingParticipants = selectParticipantsWhoDoNotHaveATurn(state, { participants: breakoutRoom?.participants, breakoutId });

      const { turnStartTime, turnEndTime, intermissionStartTime, returnedNow } = this.createTurnTime(activeRound);
      // TODO ZW: Rely on isLocalFacilitator to end turn
      if (remainingParticipants.length === 0 && remainingTurns === false) {
         if (activeRound) {
            const round = new Round({
               room: this.room,
               Round: activeRound,
               store: this.store
            });
            // dispatch event to end the current Round
            round.endRound(breakoutId);
         }
      } else if (nextReflectiveTurn) {
         this.sendDataPacket({
            payload: activeRound?.strictTime
               ? {
                    type: "turn/startTurn",
                    payload: {
                       id: nextReflectiveTurn.id,
                       breakoutId: nextReflectiveTurn.breakoutId,
                       turnStartTime: turnStartTime,
                       turnEndTime: turnEndTime,
                       intermissionStartTime: intermissionStartTime,
                       fallback: {
                          ...nextReflectiveTurn,
                          id: nextReflectiveTurn.id,
                          turnStartTime: turnStartTime,
                          turnEndTime: turnEndTime,
                          intermissionStartTime: intermissionStartTime
                       }
                    }
                 }
               : {
                    type: "turn/queueTurn",
                    payload: {
                       id: nextReflectiveTurn.id,
                       breakoutId: nextReflectiveTurn.breakoutId,
                       fallback: nextReflectiveTurn
                    }
                 }
         });
      } else if (activeListeningTurns.length > 0) {
         if (activeRound?.strictTime) {
            this.sendDataPacket({
               payload: {
                  type: "turn/startTurn",
                  payload: {
                     id: activeListeningTurns[0]?.id,
                     turnStartTime: turnStartTime,
                     turnEndTime: turnEndTime,
                     intermissionStartTime: intermissionStartTime,
                     fallback: {
                        ...activeListeningTurns[0],
                        id: activeListeningTurns[0]?.id,
                        turnStartTime: turnStartTime,
                        turnEndTime: turnEndTime,
                        intermissionStartTime: intermissionStartTime
                     }
                  }
               }
            });
         } else if (!activeRound?.strictTime) {
            this.sendDataPacket({
               payload: {
                  type: "turn/queueTurn",
                  payload: {
                     id: activeListeningTurns[0]?.id,
                     breakoutId: activeListeningTurns[0]?.breakoutId,
                     fallback: activeListeningTurns[0]
                  }
               }
            });
         }
      }
   }
   hasRemainingTurns(breakoutId: string) {
      validateParams({ breakoutId });
      const state = this.store.getState();
      const currentRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, currentRound?.id);

      const remainingTurns = turns.filter((turn) => turn?.turnComplete === false && !turn?.void);

      if (remainingTurns.length > 0) {
         return true;
      } else {
         return false;
      }
   }
   createTurnTime(
      round?: R.Round.Round
   ):
      | { intermissionStartTime: number; turnStartTime: number; turnEndTime: number; returnedNow: number }
      | { intermissionStartTime: number; returnedNow: number; turnEndTime: null; turnStartTime: null } {
      const now = DateTime.now();
      const returnedNow = now.toMillis();
      const intermissionStartTime = now.toMillis();
      if (round) {
         const turnStartTime = now.plus(round?.intermissionTime * 1000).toMillis();
         const turnEndTime = now
            .plus(round?.intermissionTime * 1000)
            .plus(round?.speakerTime * 1000)
            .toMillis();

         return { intermissionStartTime, turnStartTime, turnEndTime, returnedNow };
      } else {
         return { intermissionStartTime, returnedNow, turnEndTime: null, turnStartTime: null };
      }
   }
   turnTimeLeft(): {
      turnTimeLeftInt: number | undefined;
      intermissionTimeLeftInt: number | undefined;
      intermissionTimeLeftReadable: string | undefined;
      speakingTimeLeftReadable: string | undefined;
   } {
      const turn = this.Turn;

      if (!turn.turnStartTime || !turn.turnEndTime) throw new Error("turnTimeLeft: turnStartTime or turnEndTime is undefined");
      if ((!turn.turnStartTime || !turn.turnEndTime) && PublicEnv.NodeEnv !== "production") throw new Error("");
      //
      const intermissionTimeLeft = DateTime.fromMillis(turn.turnStartTime).diffNow().toMillis();
      const humanReadableIntermissionTimeLeft = DateTime.fromMillis(turn.turnStartTime).diffNow().toFormat("mm:ss");
      const turnTimeLeft = DateTime.fromMillis(turn.turnEndTime).diffNow().toMillis();
      const humanReadableSpeakingTimeLeft = DateTime.fromMillis(turn.turnEndTime).diffNow().toFormat("mm:ss");

      return {
         turnTimeLeftInt: turnTimeLeft,
         intermissionTimeLeftInt: intermissionTimeLeft,
         intermissionTimeLeftReadable: intermissionTimeLeft > 0 ? humanReadableIntermissionTimeLeft : "00:00",
         speakingTimeLeftReadable: turnTimeLeft > 0 ? humanReadableSpeakingTimeLeft : "00:00"
      };
   }

   resumeTurnTime():
      | { intermissionStartTime: number; turnStartTime: number; turnEndTime: number; returnedNow: number }
      | { intermissionStartTime: null; turnStartTime: null; turnEndTime: null; returnedNow: number } {
      const now = DateTime.now();
      const returnedNow = now.toMillis();
      const turn = this.Turn;
      if (!turn?.intermissionStartTime || !turn?.turnStartTime || !turn?.turnEndTime || !turn?.turnPausedTime) throw new Error("resumeTurnTime: turnStartTime or turnEndTime is undefined");
      //  if (turn?.intermissionStartTime && turn?.turnStartTime && turn?.turnEndTime && turn?.turnPausedTime) {
      const oldIntermissionStartTime = DateTime.fromMillis(turn.intermissionStartTime);
      const oldTurnStartTime = DateTime.fromMillis(turn.turnStartTime);
      const oldTurnEndTime = DateTime.fromMillis(turn.turnEndTime);

      const pausedDuration = DateTime.fromMillis(turn.turnPausedTime).diffNow().toMillis();

      const intermissionStartTime = oldIntermissionStartTime
         .minus({
            milliseconds: pausedDuration
         })
         .toMillis();
      const turnStartTime = oldTurnStartTime
         .minus({
            milliseconds: pausedDuration
         })
         .toMillis();
      const turnEndTime = oldTurnEndTime
         .minus({
            milliseconds: pausedDuration
         })
         .toMillis();

      //
      return { intermissionStartTime, turnStartTime, turnEndTime, returnedNow };
      /*  } else {
         return { intermissionStartTime: null, turnStartTime: null, turnEndTime: null, returnedNow };
      } */
   }
   addTurnEvent(params: R.Room.DataReceivedParams) {
      const { payload: data } = params.action;

      this.dispatch(params.action);

      if (params.localHost && data.firstTurn) {
         this.voidRemainingFirstTurns();
      }

      if (data.speakerIdentity === this.localParticipant?.identity && data.void === false) {
         this.store.dispatch({
            type: "app/setAllMenus",
            payload: { leftNavOpen: true, listeningRequestNavOpen: true }
         });
      }
   }

   endTurnEvent(params: R.Room.DataReceivedParams) {
      const { payload: data } = params.action;
      this.dispatch(params.action);

      if (params.isLocalFacilitator) {
         this.remainingTurns();
      }
   }

   acceptListeningRequestEvent(params: R.Room.DataReceivedParams) {
      this.dispatch(params.action);
   }
   voidListeningRequestsByTurnId() {
      const { turn } = validateParams({ turn: this.Turn }) as { turn: R.Turn.Turn };
      // TEST TODO: Make sure the host can only void listening requests for the activeTurn.
      // Must void all turns that are incomplete, and inactive
      // Must void all listening requests for incomplete and inactive turns.
      const state = this.store.getState();

      // const listeningRequests = selectListeningRequestsByTurnId(state, turnId);

      // Select all incomplete and inactive turns for the activeRound
      const incompleteInactiveTurns = selectIncompleteAndInactiveTurnsByRoundId(state, turn?.roundId);

      // Select all listening requests for the incomplete and inactive turns
      const incompleteInactiveListeningRequests = [] as R.ListeningRequest.ListeningRequest[];

      incompleteInactiveTurns.forEach((turn) => {
         const LRs = selectListeningRequestsByTurnId(state, turn?.id);
         incompleteInactiveListeningRequests.push(...LRs);
      });

      const activeTurnListeningRequests = selectListeningRequestsByTurnId(state, turn?.id);
      //
      /*   const listenerTurn = selectActiveRoundTurnBySpeakerIdentity(
         state,
         turn.listenerIdentity
       ); */
      incompleteInactiveListeningRequests.forEach((lr) => {
         this.sendDataPacket({
            payload: {
               type: "listeningRequest/voidListeningRequest",
               payload: {
                  id: lr.id,
                  listenerIdentity: lr.listenerIdentity,
                  fallback: lr
               }
            }
         });

         if (lr.listenerIdentity === this.localParticipant.identity) {
            toast.dismiss(lr.id);
         }
      });

      activeTurnListeningRequests.forEach((lr) => {
         this.sendDataPacket({
            payload: {
               type: "listeningRequest/voidListeningRequest",
               payload: {
                  id: lr.id,
                  listenerIdentity: lr.listenerIdentity,
                  fallback: lr
               }
            }
         });
         if (lr.listenerIdentity === this.localParticipant.identity) {
            toast.dismiss(lr.id);
         }
      });

      this.sendDataPacket({
         payload: {
            type: "turn/cancelListener",
            payload: {
               id: turn?.id,
               fallback: {
                  id: turn?.id,
                  fallback: turn
               }
            }
         }
      });

      /* incompleteInactiveTurns.forEach((turn) => {
         this.sendDataPacket({
            type: "turn/voidTurn",
            payload: {
               id: turn?.id,
               fallback: turn
            }
         });
      }); */
   }
   receiveEvent(params: R.Room.DataReceivedParams) {
      const { type: type, payload: data } = params.action;

      switch (type) {
         case "turn/acceptListeningRequest":
            this.acceptListeningRequestEvent(params);
            break;
         case "turn/addTurn":
            this.addTurnEvent(params);
            break;
         case "turn/endTurn":
            this.endTurnEvent(params);
            break;
         case "turn/addTurns":
         case "turn/cancelListener":
         //   case "turn/confirmTurn":
         case "turn/pauseTurn":
         case "turn/queueTurn":
         case "turn/resumeTurn":
         case "turn/removeTurn":
         case "turn/startTurn":
         case "turn/updateTurn":
         case "turn/voidTurn":
         case "turn/upsertTurn":
         case "turn/upsertTurns":
            this.dispatch(params.action);
            break;
         default:
            throw new Error(`receiveEvent: ${type} is an unhandled event type`);
      }
   }
}
